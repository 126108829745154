/*
 |--------------------------------------------------------------------------
 | Mobile
 |--------------------------------------------------------------------------
 */

.menuOverlay {
    position: fixed;
    z-index: 2001;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.65);
}

.menuOverlay.active {
    display:block;
}



/*
 |--------------------------------------------------------------------------
 | Media Queries - Small Devices - Tablets, 768px and up
 |--------------------------------------------------------------------------
 */

@media (min-width: 768px) { 
    #quote-carousel 
    {
      margin-bottom: 0;
      padding: 0 40px 30px 40px;
    }
        
    #quote-carousel .carousel-indicators {
        bottom: -20px !important;  
    }
    #quote-carousel .carousel-indicators li {
        display: inline-block;
        margin: 0px 5px;
        width: 15px;
        height: 15px;
    }
    #quote-carousel .carousel-indicators li.active {
        margin: 0px 5px;
        width: 20px;
        height: 20px;
    }
}

/*
 |--------------------------------------------------------------------------
 | Media Queries - Smaller Laptops
 |--------------------------------------------------------------------------
 */

@media (min-width : 1200px) and (max-width : 1600) {

    .ui.secondary.pointing.menu .item {
        font-size:14px;
    }

    .map-editor-navigation {
        height: 100% !important;
        min-height: 250px !important;
    }

    .city-sorter .filter {
        font-size: $small-font;
    }

    .ui.cards>.card {
        width:30%;
    }

    #event-wrapper {
        padding: 10px;
        margin-top: 70px;
        width: 98%;
        margin-left: 1%;
        margin-right: 1%;
    }

    #event-wrapper .home-tag {
        font-size: 18px;
        text-align: center;
    }

    #homenav .navi {
        float: right;
        margin-top: 16px;
        font-size: $small-font;
    }

}

/*
 |--------------------------------------------------------------------------
 | Media Queries - Tablet
 |--------------------------------------------------------------------------
 */


@media (min-width : 768px) and (max-width : 1024px) {

    .top-features li {
        display: inline;
        padding: 0px 15px;
        font-size: $large-font;
        font-weight: 300;
    }

    .mix, .mix-2 {
        width:100%;
    }

    .map-creation .selected-map-type:hover, .selected-map-style {
        cursor: pointer;
        width: 33%;
        float: left;
    }

    .ui.cards>.card {
        width:95%;
    }

    .navbar-default .navbar-brand {
        width:100%;
    }

    .new-lang-search .content {
        padding-top:0px;
    }

    .new-lang-search {
        padding: 50px 80px 125px 80px;
    }

    .new-lang-explore-search {
        padding:0px;
    }

    #event-detail-rating {
        position: absolute;
        top: -400px;
    }

    .account-head {
        margin-top: 0px;
    }

    form.container.col-md-6.margins15 {
        clear:both;
        width:95%;
    }

    .container {
        width:100%;
    }

    .cover .col-md-2 {
        width: 16.6666666667%;
        float: left;
    }
    
    .map-details {
        width: 50%;
        float: left;
    }

    .map-actions {
        width: 33.3333333333%;
        float: left;
    }

    .get-started .col-md-6 {
        width:50%;
        float:left;
    }

    .selling-wrapper .col-md-4 {
        width: 50%;
        float:left;
        margin-top:50px;
        margin-bottom:50px;
    }

    .col-md-6 {
        width: 50%;
        float: left;
    }

    .selling-wrapper .clear {
        clear: inherit;
    }

}

/*
 |--------------------------------------------------------------------------
 | Media Queries - Mobile Devices
 |--------------------------------------------------------------------------
 */


@media (min-width : 320px) and (max-width : 768px) {


    #base-main {
        display:none;
    }

    #base-response {
        display:block !important;
    }

    .home-nav-white {
        padding:0px;
    }

    .content {
        padding: 0px;
    }

    .new-lang-search .ui.selection.dropdown, .new-lang-search .action, .new-lang-search .search, .new-lang-search .ui.input {
        width:100%;
    }

    .new-lang-explore-search {
        margin-bottom:-1px;
        margin-left:0px;
        display:none;
    }

    #myLargeModalImage {
        height:250px !important;
    }

    #event-photo .inner-item-details {
        margin-bottom:2px;
    }

    .inner-item-wrap {
        margin-bottom:50px;
    }

    .settings-drop {
        position: fixed;
        left: 0px;
        right: 0px;
        width: 100%;
        top: 30px;
        margin-left: 0px;
    }

    #home-new-lang-search {
        padding: 0px;
        margin: 0px;
    }

    #emailModal {
        width: 90%;
        left: 52.5%;
        height: 425px !important;
    }

    a.dropdown-toggle.account {
        position: absolute;
        top: -5px;
        right: 0px;
        background:transparent;
    }


    a.dropdown-toggle.account .name {
        display:none;
    }

    .account .fa-angle-down, .account .fa-angle-up {
        display:none;
    }

    .new-lang-search .content {
        padding:0px;
    }

    .pages-footer .col-md-3 {
        padding-bottom:50px;
    }

    .fixed-list-toggle {
        display:block;
    }

    .click-to-call {
        display:none;
    }

    .mob-click-to-call {
        display: block;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
        background: #2a2d33;
        text-align: center;
        z-index: 50000;
        padding: 10px 0px;
        color: $white-color;
    }

    .verified-secure {
        width:80%;
    }

    .ui.cards>.card {
        width: 45%;
    }
    .pad100 {
        padding: 50px 0px;
    }
    .main-tagline {
        font-size: 42px;
    }

    .breadcrumb-bar {
        display:none;
    }

    .wrap2.home-marketing-2 {
        display:none;
    }

    .sign-in {
        width: 90%;
        margin-top:0px;
        margin-bottom: 0px;
        left: 0%;
    } 

    .home-tagline {
        line-height: 36px;
        width: 80%;
        margin: 0 auto;
        font-size:20px;
    }

    .login-form .form-control { 
        width: 100% !important;
        margin-top: 10px;
    }

    .top-features {
        display:none;
    }

    .top-features li {
        width: 100%;
        display: block;
        margin: 25px 0px;
    }

    // .navi {
    //      width: 0% !important;
    //     top: 0px;
    //     margin: 0px;
    //     padding: 0px;
    //     height: 0px;
    // }

    // .navi .mob-hide {
    //     display:none;
    // }

    #baseDrop {
        height: 100%;
        overflow-y: scroll;
        padding: 50px 10px 100px 10px;
    }

    #baseDrop #baseDesc {
        display: none;
    }

    #baseDrop .col-md-3 {
        padding: 0px 0px 25px 0px;
    }

    #baseDrop .base-box {
        padding:10px;
    }

    #homenav .navi {
        top:4px !important;
    }

    .intro .btn {
        width: 80%;
    }

    #explore-navigation {
        width: 100%;
        padding: 20px 0px;
        margin-bottom: 15px;
    }

    #explore-navigation .pull-right {
        display:none;
    }

    #map-title {
        margin:0px;
        padding: 15px 15px 0px 15px;
    }

    .notifications {
        margin-top:49px;
        border-top:0px;
        width:100%;
    }

    .fa-times:before {
        content: "\f00d" !important;
    }

    .branded {
        height:36px;
        margin: 0 auto;
        margin-top: -2px;
    }

    .home-nav {
        background: $white-color;
        padding:0px;
    }


    .branded-pages {
        margin-top:-4px;
        height:36px;
        padding:0px;
    }

    .pages-header {
        background: $secondary-color;
        padding: 0px;
    }

    .dash-data {
        width:100%;
    }

    .navbar-fixed-top {
        position:relative;
    }
    .navbar-default .navbar-brand {
        width:100%;
    }

    .nav.navbar-nav.navbar-left {
        display:none;
    }

    .response-nav {
        display:block;
    }

    .sidebar {
        display:none;
    }

    .dashboard-content {
        margin-top: 0px;
        margin-left: 0px;
        width: 95%;

    }

    .content2 {
        margin: 0 auto;
        width: 90%;
        margin-top: 50px;
    }

    .account-head {
        margin-top: 0px;
    }

    form.container.col-md-6.margins15 {
        clear:both;
        padding:25px 0px;
    }

    button.btn.btn-success.btn-lg.pull-right.margins15 {
        width: 100%;
        margin: 15px 0px;
        padding: 15px;
    }

    .navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:hover, .navbar-default .navbar-nav>.open>a:focus {
        background-color: transparent;
        color: $white-color;
    }

    .navbar-default .navbar-nav .open .dropdown-menu>li>a, .navbar-default .navbar-nav .open .dropdown-menu>li>a:hover , .navbar-default .navbar-nav .open .dropdown-menu>li>a:focus {
        color:$white-color;
    }

    .navbar-default .navbar-toggle {
        border-color: $white-color;
    }
    .navbar-default .navbar-toggle .icon-bar {
        background-color:$white-color;
    }

    .navbar-toggle .icon-bar {
        display: block;
        width: 25px;
        height: 3px;
        border-radius: 0px;
    }

    .navbar-toggle {
        border: 0px solid transparent;
        border-radius: 0px;
    }

    nav.container {
        background: $secondary-color;
    }

    #homenav {
        background: transparent;
        text-align: center;
        padding: 8px 0px 0px 0px;
    }

    #extra-nav {
        position: relative;
        top: 0px;
        padding: 0px;
    }

    #homenav a.navbar-brand {
        float:none;
        font-size:24px;
        padding:0px;
    }

    .navbar-brand > img {
        display: inherit;
        margin-top:-5px;
    }


    .navbar-default .navbar-nav>li>a, .navbar-default .navbar-nav>li>a:hover, .navbar-default .navbar-nav>li>a:focus {
        color:$white-color;
        background:none;
    }

    ul.dropdown-menu {
        border-radius: 0px;
        background: $white-color;
    }

    ul.dropdown-menu li {
        height: 36px;
    }

    .dropdown-toggle span.caret {
        display:none;
    }

    a.navbar-brand.col-md-2 {
        font-size: 24px;
        text-align: center;
    }

    .btn {
        margin: 5px 0px;
    }

    html, body {
        height:100%;
    }

    #map {
        margin-top: 0px !important;
    }

    .account-CTA {
        position: fixed;
        bottom: 0px;
        left: 0px;
        right:0px;
        width: 100%;
        margin-bottom: 0px;
    }

    .sticky-message {
        width:100%;
    }

    .modal.in .modal-dialog {
        margin: 0 auto;
    }

    .download-wrap {
        padding:50px 0px;
    }

    .home-signup span {
        width:100%;
        left:0px;
        float:none;
    }

    .home-signup input {
        width:100%;
    }

    .map-title-name {
        display:none !important;
    }

    .home-left img {
        display:none !important;
    }

    .banner .pad-text {
        padding: 25px 0px;
    }

    .banner.type-3 .inner-wrap {
        padding:25px;
    }

    .map-navigation {
        display:block;
        width: 100%;
        margin-top: -10px !important;
    }

    .sorting-bar {
        width:100%;
        top:0px;
        margin: 0 auto;
    }

    #city-list, #map-list {
        width: 100%;
        margin-top: 104px;
        margin-bottom: 0px;
    }

    .ui-button, .ui-button:hover, .ui-button:active, .ui-button:visited {
        position: fixed;
        top: 10px;
        z-index: 1000;
        right: 6px;
        padding: 6px;

    }

    .map-mapview {
        height: 100% !important; 
        width: 100% !important; 
        position: fixed; 
        top: 50px;
    }

    .fixed-onboard {
        display:block;
    }

    .explore-helper {
        width:80%;
    }

    .sorting-bar button#dLabel {
        font-size:14px;
    }

    .modal {
        z-index: 999999;
    }

    .price-right {
        border:0px;
    }

    .filter-caret {
        right:5px;
    }

    .sorting-bar ul {
        margin-top:-5px;
    }

    .sorting-bar ul li {
        padding:0px 5px;
    }

    #mobile-only-loader {
        position:fixed;
        height:75px;
        width:75px;
        background:$white-color;
        left:42.5%;
        top:45%;
        border-radius:15px;
        text-align:center;
    }

    #mobile-only-loader img {
        margin-top:15px;
    }

    .map-home-title {
        margin-top:0px !important;
    }

    .leaflet-popup-pane {
        display:block !important;
    }

    .btn-white {
        width:100%;
    }

    .map-cover {
        width:100% !important;
    }

    /* Temporarily remove prompt for ios app installation */

    .fixed-onboard {
        display:none;
    }

    .mob-friendly{
        display:none !important;
    }

    .homepage-CTA {
        width:80%; 
        margin-top:25px;
    }

    .map-cover .user-profile {
        top:5%;
    }

    .index-tag {
        padding: 50px 0px; 
        font-size: 30px; 
        font-weight: 300; 
        width: 90%; 
        margin: 0 auto; 
        margin-top: 5% !important;
    }

    .inner-map-wrap:hover {
        background: rgba(42,45,51,0.0) !important;
    }

    .ghost-lt-btn, .ghost-lt-btn:hover {
        display: none !important;
    }

    #login-box {
        padding-top:0px;
    }

    #results-loader {
        margin-top:50px;
    }

    .dropdown-menu>li>a {
        font-size: $large-font;
        font-weight: 300;
    }

    #region-desc { 
        width:90%;
    }

    #event-detail-rating {
        top:-260px;
    }

    .inner-map-wrap {
        padding: 110px 0px;
    }

    .user-stats {
        width:90%;
    }

    #base-search {
        display:none;
        position: fixed;
        top: 50px;
        left: 0px;
        right: 0px;
        border: 0px;
        border-radius: 0px;
        border-top: 1px solid $light-gray-color;
        border-bottom: 1px solid $light-gray-color;
        padding: 15px 0px;
        text-align: left;
        background:$white-color;
        width:100%;
    }

    .secondary-prof-spacing {
        margin-top:50px;
    }

    .secondary-region-spacing {
        margin-top:-50px;
    }
    .secondary-map-layout {
        height: 125px !important;
    }

    .secondary-map-cover {
        height: 125px !important;
        margin-top: -125px;
    }

    .secondary-edit-spacing {
        text-align: left;
        width: 100%;
        margin: 0 auto;
        margin-top: -150px;
    }

    #editProfileButton {
        left: 150px;
        padding: 5px;
    }

    #followButton {
        left: 140px;
        width: 90px;
    }

    .home-title-name {
        font-size: 20px;
        font-weight: 400;
        text-transform: uppercase;
        margin-top: 100px !important;
        width: 80%;
        margin: 0 auto;
        margin-bottom: 40px;
     }
     
     .home-sort-bar {
        margin-top:25px;
      }
      
    .mapping-cover-img {
        margin-bottom: 0px;
        width: 100%;
        height: 200px;
        box-shadow: 0px 2px 3px $light-gray-color;
        border: 0px;
        border-radius: 0px;
    }

    .dropdown {
        display:none;
    }

    .home-padding {
        padding: 50px 0px 0px 0px;
    }

    .product-wrapper img#floatEffect {
        margin-top: -60px;
        padding-bottom: 40px;
    }

    .product-wrapper .btn-alt {
        width: 100%;
        text-align: center;
        margin-top: 20px;
    }

    .product-details-wrapper.col-md-8.col-md-offset-2 .col-md-6 {
        padding: 40px 0px;
    }

    .mobile-btn {
        display: block;
        text-align: center;
    }

    .mobile-title {
        text-align: center;
    }

    .team-mobile {
        width:100%;
    }

    .team-mobile-padding {
        padding-bottom: 200px;
    }

    .inverted-product-details {
        padding: 100px 15px 250px 15px;
    }

    .cta-banner.text-center {
        padding: 100px 0px 100px 0px;
    }

    .sales-box {
        width:100%;
    }

    .page-head p, .landing-page-head p {
        width:80%;
    }

    .product-details-wrapper .col-md-4 {
        padding: 0px 0px 40px;
    }

    .floating-box {
        width:100%;
    }

    .banner .sentence {
        padding:50px 0px;
        width:90%;
        margin:0 auto;
        text-align: center;
    }

    .sales-box .title {
        font-size:20px;
    }

    .pricing-box {
        padding:20px 0px !important;
        width:100%;
        border: 0px;
        box-shadow: none;
    }

}

/*
 |--------------------------------------------------------------------------
 | Push Navigation Styles
 |--------------------------------------------------------------------------
 */

#showLeftPush, #showRightPush {
    display:none;
}

.cbp-spmenu {
    background: $white-color;
    position: fixed;
}

.cbp-spmenu h3 {
    border: 0px;
    font-size: 12px;
    color: gray;
    letter-spacing: .12em;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0px 15px;
    margin: 50px 0px 0px 0px;
}

.cbp-spmenu a {
    display: block;
    color: $dark-color;
    font-weight: 300;
    text-decoration: none;
}

.cbp-spmenu a:hover {
    color:$dark-color;
    opacity:1.0;
}

.cbp-spmenu-vertical {
    width: 80%;
    height: 100%;
    top: 0;
    z-index: 5000;
}

.cbp-spmenu-vertical a {
    border-bottom: 0px solid #333;
    padding: 1em;
    letter-spacing: normal;
    font-size: 25px;
    margin-left: 0px;
    margin-bottom: -10px;
}

.push-subnavigation .fa {
    width: 40px;
    margin-left: -10px;
    text-align: center;
}

.cbp-spmenu-horizontal {
    width: 100%;
    height: 150px;
    left: 0;
    z-index: 5000;
    overflow: hidden;
}

.cbp-spmenu-horizontal h3 {
    height: 100%;
    width: 20%;
    float: left;
}

.cbp-spmenu-horizontal a {
    float: left;
    width: 20%;
    padding: 0.8em;
    border-left: 1px solid #258ecd;
}

.cbp-spmenu-left {
    left: -80%;
}

.cbp-spmenu-right {
    right: -80%;
}

.cbp-spmenu-left.cbp-spmenu-open {
    left: 0px;
    z-index:4000;
    box-shadow: 0px 2px 6px $dark-color;
    overflow-y: scroll;
}

.cbp-spmenu-right.cbp-spmenu-open {
    right: 0px;
    z-index: 5000;
}

.push-subnavigation {
    list-style-type: none;
    padding: 0px;
}

.push-subnavigation li a {
    font-size: $large-font;
    margin-left: 0px;
    padding: 10px 0px;
    letter-spacing: normal;
    text-transform: capitalize;
    font-weight: 300;
    margin-left:15px;
}

.cbp-spmenu-top {
    top: -150px;
}

.cbp-spmenu-bottom {
    bottom: -150px;
}

.cbp-spmenu-top.cbp-spmenu-open {
    top: 0px;
}

.cbp-spmenu-bottom.cbp-spmenu-open {
    bottom: 0px;
}

.cbp-spmenu-push {
    overflow-x: hidden;
    position: relative;
    left: 0;
}

.cbp-spmenu-push-toright {
    left: 0px;
}

.cbp-spmenu-push-toleft {
    left: -240px;
}

.cbp-spmenu,
.cbp-spmenu-push {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 5000;
}


@media screen and (max-width: 55.1875em){

    .cbp-spmenu-horizontal {
        font-size: 75%;
        height: 110px;
    }

    .cbp-spmenu-top {
        top: -110px;
    }

    .cbp-spmenu-bottom {
        bottom: -110px;
    }
}

@media screen and (max-height: 26.375em){

    .cbp-spmenu-vertical {
        font-size: 90%;
        width: 240px;
    }

    .cbp-spmenu-left,
    .cbp-spmenu-push-toleft {
        left: -240px;
    }

    .cbp-spmenu-right {
        right: -240px;
    }

    .cbp-spmenu-push-toright {
        left: 0px;
    }
}

@media (min-width : 320px) and (max-width : 768px) {

    #features {
        display: none;
    }

    #showLeftPush, #showLeftPush:hover, #showLeftPush:focus {
        display: block;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 60px;
        background: $white-color;
        height: 50px;
        z-index: 5000;
        color: $white-color;
        opacity:1.0;
    }


    #showRightPush, #showRightPush:hover, #showRightPush:focus {
        display: block;
        position: fixed;
        top: 0px;
        right: 0px;
        width: 60px;
        background: $white-color;
        height: 50px;
        z-index: 2001;
        color: $gray-color;
        opacity:1.0;
    }

    #showLeftPush {
        background:none !important;
    }

    #showLeftPush div {
        margin:15px;
    }

    #showLeftPush div:after {
        color: $secondary-color;
        font-size: 24px;
        font-family: 'FontAwesome';
        content:'\f0c9';
    }

    #showLeftPush.active div:after{
        color:$secondary-color;
        font-size: 30px;
        font-family: 'FontAwesome';
        content:'\f00d';
        webkit-transition: all 1s cubic-bezier(0.190,1.000,0.220,1.000);
        transition: all 1s cubic-bezier(0.190,1.000,0.220,1.000);
        -webkit-text-stroke: 2px white;
    }

    #showRightPush span {
        position: relative;
        top: 14px;
        font-size: 20px;
        left: 20px;
    }

    .bread-back, .bread-back:hover, .bread-back:focus {
        display:none;
    }

    .navbar-nav {
        display:none;
    }

    .navbar-fixed-top {
        position: absolute;
        right: 0px;
        top: 0px;
    }

    .dashboard-content {
        padding: 0px 5%;
        text-align: left;
        left:0px;
        top:70px;
    }

    .get-started {
        top: 0px;
        width: 100%;
        height: 100%;
        bottom:0px;
    }

    .get-started .col-md-6 div {
        display:none;
    }

    .get-started .modal-footer a {
        width:100%;
    }

    .carousel {
        position: relative;
        top: 10px;
    }

    .alert {
        font-size:14px;
    }

    .dash-btn {
        width:100%;
        margin-bottom:10px;
    }

    figure.effect-bubba h2 {
        padding-top: 30px;
    }

    .grid figure {
        max-height:200px;
    }

    .landing-page-head {
        padding:50px 10px;
    }

    .landing-page-head h2 {
        font-size: 24px;
        text-align: center;
    }

    .vacation-banner h3 {
        width:80%;
        font-size:24px;
    }

    #emailModal {
        width: 100% !important;
        position: fixed !important;
        top: 0px !important;
        left: 0px !important;
        right: 0px !important;
        bottom: 0px !important;
        margin: 0 auto !important;
        height: 100% !important;
        border-radius: 0px !important;
    }

    .ui.modal>.close {
        top: .25rem!important;
        right: 0rem!important;
    }

    .page-head h2, .landing-page-head h2 {
        font-size:50px;
        text-align: center;
    }

    .landing-page-head p, .page-head p {
        font-size: 25px;
        padding: 15px 0px;
        text-align: center;
    }

    .welcome-panel {
        padding: 0px 0px 50px 0px;
    }

    .btn-alt {
        width:100%;
        margin-bottom:25px;
    }

    .mobile-full, .ui.four.statistics .statistic,  .col-md-4 {
        width:100%;
    }

    #features {
        z-index: 9999 !important;
        width: 100% !important;
        background: $white-color;
    }

    .mapboxgl-map {
        width:100% !important;
        z-index:5001;
    }

    .built-on-base { 
        left: 2%;
    }

    .mapboxgl-ctrl-top-left {
        left:10px !important;
    }

    .dashboard-locations {
        margin-right: 80px;
        top: 52px;
    }

   .btn-primary {
        max-width: 100%;
    }

    .dashboard-locations .map-actions {
        text-align: center;
    }

    .dashboard-locations .cover {
        padding: 30px 0px 75px 0px;
        text-align:center;
    }

    #upgradeModalLabel {
        font-size: $small-font;
    }

    .upload-photo, #saveIconBtn {
        width:100% !important;
    }

    .marker-category-selection {
        position: relative;
        height: 1000px;
    }

    .map-editor-navigation {
        height: 100% !important;
        top: 50px !important;
        width: 50px !important;
    }

    .map-editor-navigation li {
        display:none;
    }

    .shops img {
        width: 50%;
        float: left;
        padding: 20px 10px;
    }

    .shops .pad15 {
        display:none;
    }

    .bar {
        top:50px;
    }

    .map-creation .selected-map-type:hover, .selected-map-style {
        cursor: pointer;
        width: 50%;
        float: left;
    }

    .fixed-type-menu {
        top:50px !important;
        overflow-x: scroll;
    }
 
    .base-map-pre-wrapper {
        left:0px;
    }

    .base-map-pre-loader {
        left:65%;
    }

    .inner-wrapper .col-md-11 {
        width: 85%;
    }

    .base-pre-wrapper {
        background: #ffffff;
        position: fixed;
        left: 80px;
        right: 0px;
        bottom: 0px;
        top: 50px;
        z-index: 1000;
    }

    .inner-wrapper .ui.tab.segment.col-md-11 {
        width: 85%;
        left: 55px;
        top:40px;
    }

    .dashboard-content-offset {
        left:55px;
    }

    .plan-option {
        width: 100%;
        float:none;
    }


    .landing-screenshot {
        margin-top:0px;
        padding-bottom: 50px;
    }

    .services-box {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .selling-wrapper .clear {
        clear: inherit;
        padding: 0px;
    }

    .sell-head {
        margin-top: 50px;
    }

    .info-box {
        width:100%;
    }

    .page-page-head .intro {
        left: 0px;
    }

    .page-page-head .intro h2 {
        text-align: center;
        font-size:50px;
    }

    .page-page-head .intro p {
        font-size: 25px;
        width:100% !important;
        text-align: center;
    }


    .page-page-head {
        padding: 150px 0px;
    }

    .page-page-head video {
        display:none;
    }

    #requestdemo {
        display:inline-block;
    }


    .brand-logo {
        margin-left: 0px;
    }

    #mapbox-map {
        position: relative;
        right: 0px;
        bottom: 0px;
        left: 50px;
        height: 250px;
        z-index: -1;
        margin-bottom: 100px;
    }

    .button-bar {
        position: fixed;
        bottom: 20px;
        left: 55px;
        padding: 10px;
        width: 85%;
    }

    .button-bar .btn-primary {
        width: 100%;
    }

    #viewMap {
        display:none;
    }

    #createResponsive {
        position: absolute;
        bottom: 10px;
        font-size: 15px;
        color: $white-color;
        margin: 0 auto;
        width: 90%;
        left:14px;
    }

    #createMapButton {
        max-width: 100%;
        box-shadow: 0 14px 28px rgba(0,0,0,0.1), 0 10px 10px rgba(0,0,0,0.12);
    }

    /* Base Capital Homepage */

    .home-page-head {
        margin-top:-20px;
    }

    .home-page-head .intro p {
        width: 100%;
    }

    #team .inner-team h1 {
        display: none;
    }

    .team-member {
        float: none;
        width: 100%;
    }

    #team .owner-desc {
        font-size: 16px;
        width: 100%;
        margin: 0 auto;
        position: relative;
        top: 0px;
        margin-bottom: 0px;
        left: 0px;
    }

    #responsive-map {
        margin-top: 50px;
        padding: 0px;
        width: 100%;
    }

}

/* Padding Hack */
@media (max-width: 320px) {
   #features {
      display:none;
   }

   .mapboxgl-map {
        width: 100%!important;
        z-index: 5001;
    }

    .built-on-base {
        left: 2%;
    }

    .base-map-pre-wrapper {
        display: none;
    }
}
