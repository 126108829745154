/*
 |--------------------------------------------------------------------------
 | Footer
 |--------------------------------------------------------------------------
 */

footer {
	clear:both;
	padding:100px 0px;
	text-align:center;
	margin-left:150px;
}

.pages-footer {
	padding: 0px;
	background:$white-color;
	text-align:left;
	margin-left:0px;

}

.pages-footer i {
	color:$secondary-color;
}

.pages-footer h4 {
    font-size: $normal-font;
    color: gray;
}

.pages-footer ul {
	list-style-type: none;
	padding:0px;
}

.pages-footer a {
	color:$secondary-color;
	font-size:14px;
}

.pages-footer a:hover {
	color:$primary-color;
	text-decoration: none;
}

.pages-footer p{
	color:$secondary-color;
	margin: 0;
	font-size:12px;
}

.copy {
	text-align:center;
	padding:50px;
}