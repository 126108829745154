/*
 |--------------------------------------------------------------------------
 | Layout
 |--------------------------------------------------------------------------
 */

 html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}
      
body {
    font-family: $primary-font;
    font-weight: $normal-weight;
}

a {
    color: $secondary-color;
    cursor:pointer;
    font-size: 14px;
    line-height: 1.6em;
}

a:hover {
    color: $primary-color;
}

#narrow {
    font-weight:$super-light-weight;
}

#normal {
    font-weight:$light-weight;
}

.blackdrop {
    width: 100%;
    height: 100%;
    background-color: $dark-color;
    opacity: 0.3;
}

.fadein {
    opacity: 0;
}


h1 {
    font-size:60px;
}

h2 {
    font-size:44px;
}

h3 {
    font-size:36px;
    font-weight:400;
}

h4 {
    font-size:$larger-font;
    font-weight:400;
}

h1, h2, h3, h4, h5 {
    font-family: $secondary-font;
    font-weight: $bold-weight;
}

label span {
    padding: 0px 10px;
}

p {
    font-size: 16px;
    line-height: 1.6em;
}

.dashboard-content {
    position: relative;
    left: 0px;
    top: 55px;
    padding: 25px 2.5% 150px 2.5%;
    right: 0px;
}

.dashboard-content-offset {
     position: relative;
    left: 105px;
    margin-right:105px;
    top: 55px;
    padding: 25px 2.5% 150px 2.5%;
    right: 0px;   
}

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    height: calc(100%);
    width: 200px;
    overflow: hidden;
}

.widget-content {
    margin: 0 auto;
    width:80%;
}

.primary-color {
    color: $primary-color;
}

.margins5 { 
    margin:5px;
}
.margins15 { 
    margin:15px;
}
.margins25 { 
    margin:25px;
}

.pad0 {
    padding:0px;
}

.pad5 { 
    padding:5px;
}

.pad15 { 
    padding:15px;
}

.pad25 { 
    padding:25px !important;
}

.pad50 { 
    padding:50px;
}

.pad100 { 
    padding:100px;
}

.zero {
    margin: 0 auto !important;
}

.error {
    clear:both;
    text-align:center;
    padding:15px 0px;
}

.table-btn-width {
    width:120px;
}

.flt-right {
    float: right;
}

.flt-left {
    float: left;
}

.clear {
    clear:both;
}

label {
    font-weight:$bold-weight;
    color: $secondary-color;
}

::selection {
    color:$white-color;
}

.hide {
    display:none;
}

*:focus {
    outline: none;
}

*, *:after, *:before { 
    -webkit-box-sizing: border-box; box-sizing: border-box; 
}

.clearfix:before, .clearfix:after { 
    display: table; content: ''; 
}

.clearfix:after { 
    clear: both; 
}

.well {
    min-height: 100px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus, .has-error .form-control {
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;;
  box-shadow: none !important;;
}

.ui.statistic > .label, .ui.statistics .statistic > .label {
    text-transform: capitalize;
}