/*
 |--------------------------------------------------------------------------
 | Fonts
 |--------------------------------------------------------------------------
 */
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,700,900');
@import url('https://fonts.googleapis.com/css?family=Barlow');

// Font Family
$primary-font: 'Barlow', sans-serif;
$secondary-font: 'Barlow', sans-serif;

// Font Weights
$super-light-weight: 100;
$light-weight: 300;
$normal-weight: 400;
$bold-weight: 700;

// Font Sizes
$small-font: 12px;
$normal-font: 15px;
$large-font: 20px;
$larger-font: 30px;
$largest-font: 40px;