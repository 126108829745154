/*
 |--------------------------------------------------------------------------
 | Header
 |--------------------------------------------------------------------------
 */

.navbar-nav>li>.dropdown-menu {
	text-transform: capitalize;
	letter-spacing: 0px;
	width:100%;
	border-radius: 5px;
}

a.navbar-brand {
	color:$white-color;
}

.home-nav {
	padding: 5px 2.5px 0px 2.5px;
	background:$white-color;
	position: fixed;
    width: 100%;
    box-shadow: 0 2px 2px rgba(0,0,0,.2);
    box-shadow: 0 2px 2px rgba(0,0,0,.2),transparent 0 0 0,transparent 0 0 0,transparent 0 0 0,transparent 0 0 0;
    -webkit-box-shadow: 0 2px 2px rgba(0,0,0,.2),transparent 0 0 0,transparent 0 0 0,transparent 0 0 0,transparent 0 0 0;
    -moz-box-shadow: 0 2px 2px rgba(0,0,0,.2),transparent 0 0 0,transparent 0 0 0,transparent 0 0 0,transparent 0 0 0;
    -khtml-box-shadow: 0 2px 2px rgba(0,0,0,.2),transparent 0 0 0,transparent 0 0 0,transparent 0 0 0,transparent 0 0 0;
    top:0px;
}

.home-nav-white {
    padding: 15px 30px;
    background:$white-color;
    position: fixed;
    width: 100%;
    box-shadow: none;
    top:0px;
}

.home-nav-transparent {
    background: #000000;
    position: absolute;
    width: 100%;
    box-shadow: none;
    top:0px;
}

.home-nav-main {
	padding:10px;
	background:transparent;
}

.account {
	text-transform: capitalize;
	letter-spacing: normal;
}

.navbar-default .navbar-brand {
	color: $white-color;
	background: $primary-color;
	text-transform: uppercase;
	font-size: $large-font;
	width: 215px;
	font-weight: $light-weight;
	text-align: center;
	letter-spacing: 2px;
}

.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
    color: $white-color;
    background: $primary-color;
	text-transform: uppercase;
	opacity:.9;
}

.nav.navbar-nav.navbar-left {
	margin-left:25px;
}

.response-nav {
	display:none;
}

.logged-in-view {
	width:100%;
	position:fixed;
	bottom:0px;
	left:0px;
	background:#2b2d35;
	padding:15px;
	z-index: 1000;
}

.logged-in-view li {
	display:inline;
}

.logged-in-view li a, .logged-in-view li a:hover {
	color: $white-color;
	text-decoration: none;
}
	
#homenav .navi {
	float: right;
    margin-top: 15px;
    padding: 0px;
    box-shadow: none;
    letter-spacing: normal;
}

.navi {
    text-transform: capitalize;
    padding: 10px;
    width: 95%;
    margin-top: 70px;
    margin-bottom: 10px;
    margin-left: 1.25%;
}

.navi li {
	display: inline;
}

#explore-navigation {
	font-size:12px;
	padding:5px;
    margin-left:-5px;
    width:100%;
}

.navi a{
    color: $secondary-color;
    padding: 0px 25px;
    font-size:$normal-font;
}

.navi li a {
    text-transform: initial;
    letter-spacing: normal;
}

.navi a:hover, .navi a:focus {
	color:$primary-color;
	text-decoration: none;
	background:transparent;
}

.navi-home a {
	color: $white-color;
}

.navi-home a:hover, .navi-home a:focus {
	color: $light-gray-color;
}

.front-end-navi a {
	color:$white-color;
}

.front-end-navi a:hover, .front-end-navi a:focus {
	color:$white-color;
	text-decoration: none;
	background:transparent;
}


ul#explore-navigation a {
    color: $primary-color;
    font-size:$small-font;
}

.breadcrumb-bar {
	background: $off-white-color;
	width: 100%;
	height: 48px;
	font-size: $large-font;
	position: relative;
    top: 70px;
    margin-bottom: 20px;
}

.subnavi {
	float:left;
	margin-top:15px;
	padding-left: 0px;
}

.subnavi .active {
	border-bottom: 3px solid $primary-color;
	padding-bottom:10px;
}

.subnavi li {
	display: inline;
}

.subnavi a {
	color:$secondary-color;
	padding: 15px 25px;
}

.subnavi a:hover, .subnavi a:focus {
	color:$secondary-color;
	text-decoration: none;
}

.subnavi .glyphicon {
	font-size:12px;
	padding:5px;
	position:relative;
	top:0px;
}

.pages-header {
	background: #2b2d35;
	padding: 15px 0px 5px 0px;
}

.dropdown-menu {
	border-radius: 5px;
	margin-top: 27px;
	margin-left: -36px;
}

.dropdown-menu>li>a {
	color:$gray-color;
	padding:10px 20px;
	border-bottom: 0px solid $off-white-color;
	text-transform: capitalize;
}

.dropdown-menu>li>a:hover {
	color:$primary-color;
}

#homenav #explore-navigation {
	float: left;
	padding-left:0px;
}

.navbar-default .navbar-nav>li>a:hover {
	background: transparent;
	color: $secondary-color;
}

#baseDrop {
	display:none;
	position:fixed;
	top: 50px;
	left: 0px;
	right: 0px;
	background: #000000;
	z-index: 99999;
	padding: 25px 40px;
	color: #ffffff;
	height: 100%;
}

#baseDrop h4 {
	color: #fff;
	font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: normal;
    border-bottom: 1px solid #333;
    padding-bottom: 10px;
    width: 90%;
}

#baseDrop a {
	color: #ffffff;
    font-size: 20px;
    line-height: 2.0em;
    letter-spacing: 0.1em;
    text-indent: 0.5em;
    font-weight: 700;
    text-transform: uppercase;
}


#baseDrop a:hover {
	color: $primary-color;
}

#baseDrop li {
	list-style-type:none;
}

#baseClick {
	-webkit-user-select: none; /* Chrome/Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+ */

	/* Rules below not implemented in browsers yet */
	-o-user-select: none;
	user-select: none;
}

.base-box {
	background: #141414; 
	padding: 25px; 
	position: relative; 
	top: -23px;
}

#baseDrop .base-box h4 {
	border-bottom: 0px;
	padding-bottom: 0px;
}

#extra-nav {
	padding: 0px 50px; 
	position: relative; 
	top: -15px;
}

.nav-left {
	float:left;
}

.nav-right {
	float:right;
}

.nav-right ul {
	margin-bottom: 0px;
}

.nav-right ul li {
	display: inline-block;
	padding: 20px 25px;
}

.nav-right ul li a {
	color: $white-color;
	font-size: $normal-font;
}

.nav-right ul li a:hover {
	opacity: .8
}

#homenav {
    width: 80%;
    margin: 0 auto;
}

.nav-right .dropdown-menu a {
    color: $dark-color;
    display: block;
    padding: 5px 10px;
}