/*
 |--------------------------------------------------------------------------
 | Colors
 |--------------------------------------------------------------------------
 */

 $primary-color: #337ab7;
 $secondary-color: #000000;
 $dark-color: #000000;
 $red-color: #ef4545;
 $gray-color: #1e2024;
 $light-gray-color: #c2c5ce;
 $gray-accent-color: #c2c5ce;
 $white-color: #ffffff;
 $off-white-color: #f9f9f9;
 $green-color: #2fca74;