/*
 |--------------------------------------------------------------------------
 | Base
 |--------------------------------------------------------------------------
*/


.page-container {
    padding-top: 50px;
}

#sharing-install pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.brand {
	height:25px;
	width:25px;
	margin-right:10px;
}

.home-brand {
	height:125px;
	opacity:.5;
}

.home-slider {
	background-size: cover !important;
    width: 100%;
    background-position: 50% 50%;
    margin-top: -95px;
    z-index: 99999999999;
    background: rgba(0,0,0,.7);
}

.home-content{
    color: $white-color;
    padding: 40px 0px 80px 0px;
    clear: both;
    text-align: center;
}

.home-left img {
	max-height:575px;
}

.home-right {
	text-align: left;
}

.home-signup input{
	float:left;
	width: 80%;
	font-size:20px;
	padding:30px 10px;

}


.home-signup span {
	float:left;
	position:relative;
	left:-110px;
	top:6px;
	border-radius:5px;
}


.sign-in {
    margin: 0 auto;
    margin-bottom: 100px;
    text-align: center;
    border-radius: 0px;
    clear: both;
    position: relative;
    width: 75%;
}

.sign-in .info {
	font-size: $larger-font;
    font-weight: $light-weight;
}

.borderbox {
	border-style:solid;
	border-width:1px;
	border-radius:10px;
	padding:10px;
	border-color:#D4D4E3
}

.borderbox2 {
	border-style:solid;
	border-width:1px;
	border-color:#D4D4E3
}

.bread-back, .bread-back:hover, .bread-back:focus {
	margin-bottom: 35px;
	font-size: $normal-font;
	border-radius: 0px;
	padding: 15px;
	background: $white-color;
	border-bottom: 1px solid #eee;
	position: absolute;
    top: 55px;
    left: 0px;
	z-index: 1000;
	width: 100%;
}

.bread-back a, .bread-back a:hover, .bread-back a:focus {
	text-decoration: none;
	color:$primary-color;
	font-weight:300;
    padding:0px 20px;
}

.bread-back span {
	padding:0px 10px;
	color:$primary-color;
}


#toggle, #toggle-2 {
	cursor:pointer;
}

// .rotate {
//     -webkit-transform: rotate(180deg);
//     -moz-transform: rotate(180deg);
//     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
// }


.input-lg, .form-horizontal .form-group-lg .form-control {
	font-weight:300;
}

/* FireFox & Safari Input Fix */

.form-control {
    -moz-box-sizing: border-box;
    height: 100%;
    width: 100%;
}

/* ----- 2 Header ----- */ 

.map-mapview {
	height: 100% !important; 
	width: 70% !important; 
	position: fixed; 
	top: 55px; 
	right: 0px;
}

.map-cover {
	height: 100%!important;
    width: 50%!important;
    position: fixed;
    top: 55px;
    right: 0;
    background: rgba(255,255,255,.85);
    text-align: center;
    display:block;
}

.map-cover .user-profile .user-img  {
	height: 200px;
    width: 200px;
    margin: 0 auto;
    border-radius: 100px;
    box-shadow: 0px 2px 3px $gray-accent-color;
    border: 3px solid $white-color;
}

.prof-user-img  {
	height: 175px;
    width: 175px;
    margin: 0 auto;
    border-radius: 100px;
    box-shadow: 0px 2px 3px $gray-accent-color;
    border: 2px solid $white-color;
}

.side-panel-user-img {
	height: 150px;
    width: 150px;
    margin: 0 auto;
    border-radius: 75px;
    box-shadow: 0px 2px 3px $gray-accent-color;
    border: 3px solid $white-color;
}

 
 .mapping-cover-img {
    margin-top: 0px;
    margin-bottom: -50px;
    width: 100%;
    height: 200px;
    box-shadow: 0px 2px 3px $gray-accent-color;
    border-radius: 0px;
    border: 0px;
 }


.user-mini-prof-pic {
	border-radius: 100px;
    height: 30px;
    width: 30px;
    box-shadow: 0px 1px 2px $gray-accent-color;
}

.fixed-onboard {
	position: fixed;
    right: 0px;
    bottom: 0px;
    background: $off-white-color;
    width: 100%;
    z-index: 1000;
    padding: 0px 5px;
    text-align: center;
    font-size: $large-font;
    color: $secondary-color;
    display:none;
}

#followButton, #editProfileButton{
    border: 1px solid $primary-color;
    background: transparent;
    font-size: $normal-font;
    position: relative;
    font-weight: $normal-weight;
    padding: 5px 15px;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 10px;
    top: -65px;
    width: 150px;
    left: 180px;
    color: $primary-color;
}

#createNewPlus {
    position: relative;
    left: 10px;
    top:-2px;
}

#createNewPlus img {
	height:28px;
	width:28px;
}


#editProfileButton {
    cursor: pointer;
    margin:0;
   	text-decoration: none;
   	padding: 6px 30px;
    top: -55px;
}

#editProfileButton:hover {
	text-decoration: none;
}

.fixed-onboard a {
    padding: 10px 30px;
    border: 1px solid $white-color;
    border-radius: 5px;
    text-transform: capitalize;
    letter-spacing: normal;
    font-weight: $light-weight;
    font-size: $large-font;
    float: right;
    border:0px;
}

.fixed-onboard .app-install {
    position: relative;
    top: 14px;
    float: left;
    padding-left: 10px;
}

.fixed-onboard .app-image img {
	height: 40px;
    width: 40px;
    float: left;
    margin-left: 10px;
    margin-top: 5px;
}

.fixed-onboard .close-app a, .fixed-onboard .close-app a:hover {
	float: left;
    text-decoration: none;
    font-size: 20px;
    font-weight: $super-light-weight;
    position: relative;
    top: 12px;
    padding: 0px 10px 0px 15px;
}


.fixed-onboard a:hover {
	border: 0px solid $gray-color;
}

.fixed-list-toggle {
	position: fixed;
    bottom: 20px;
    background: $white-color;
    z-index: 1000;
    padding: 5px;
    width:30%;
    left:35%;
    right:35%;
    text-align: center;
    font-size: $normal-font;
    color: $primary-color;
    border-radius:25px;
    text-transform: uppercase;
    border:2px solid $gray-color;
    display:none;
}

a.fixed-list-toggle, a:hover.fixed-list-toggle {
	color:$primary-color;
	text-decoration: none;
}

.navbar-static-top {
	z-index:2000;
    position: fixed;
}

.sorting-bar {
    position: relative;
    width: 100%;
    z-index: 1000;
    top: 0px;
    border-top: 1px solid $gray-accent-color;
    border-bottom: 1px solid $gray-accent-color;
    margin-bottom: 15px;
    display:none;
}

.sorting-bar ul {
    list-style-type: none;
    padding: 10px 0px 0px 0px;
    text-align: center;
}

.sorting-bar ul li {
	display:inline-block;
	font-size: $small-font;
    text-transform: uppercase;
    padding: 0px 25px;
    font-weight: $normal-weight;
    border-right: 1px solid $off-white-color;
    width:49%;
}

.sorting-bar .last {
	border-right:0px;
}

.sorting-bar .active {
	color: $primary-color;
	font-weight:500;
}

.sorting-bar .open>.dropdown-menu {
    width: 100%;
    margin: 0 auto;
    margin-top: 5px;
	padding: 15px 0px;
}

.sorting-bar button#dLabel {
    background: $white-color;
    border: 1px solid $gray-color;
    border-radius: 5px;
    width: 100%;
    padding: 7.5px 0px;
    font-size: $large-font;
    font-weight: $light-weight;
}

.sorting-bar textarea:focus, input:focus{
    outline: none;
}


.sorting-bar *:focus {
    outline: none;
}

.sorting-bar .dropdown-menu li {
    width: 100%;
	padding: 0px 10px;
    text-align: left;
}

.sorting-bar .dropdown-menu>li>a {
	font-size: $larger-font;
    font-weight: bold;
    color: $primary-color;
}

#hidden-overlay {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    background: rgba(0,0,0,.8);
    z-index: 1000;
}

#major-menu {
    position: fixed;
    width: 100%;
    left: 0px;
    right: 0px;
    top: 70px;
    text-align: left;
    background: $white-color;
    padding: 25px 0px 50px 0px;
}
#major-menu h4.ui.header {
    font-size: $larger-font;
    font-weight: $normal-weight;
}

#major-menu .ui.list .list>a.item, .ui.list>a.item {
    font-size: $normal-font;
    color: gray !important;
    letter-spacing: normal;
}

.flipper {
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    position: relative;
    top: .5em;
}

.inner-item-wrap {
	border: 2px solid rgba(0, 0, 0, 0.075);
    border-radius: 0px;
    background: $white-color;
    margin-bottom: 20px;
}

.inner-item-details a, .inner-item-details a:hover, .inner-item-details a:focus {
	color:$white-color;
}

.inner-item-wrap div {
    padding: 15px 10px;
    color:$white-color;
    font-size: $small-font;
}

#event-photo .inner-item-details {
    position: absolute;
    bottom: -2px;
    left: 0px;
    right: 0px;
    padding: 5px;
    height: 75px;
    color: #8c939c;
    background: #ffffff;
    margin-bottom: 22px;
}

#event-name {
    font-size: $normal-font;
    color: #000;
    margin: 4px 0;
}

#rental-name {
    font-size: $normal-font;
    color: #000;
    margin: 4px 0px;
}

#rental-details {
    font-size:12px;
}

#rental-rating {
    color: #1C9854;
    font-weight: bold;
}

#at-user {
	background: transparent;
    border: 0px;
    font-size: 30px;
    padding-left: 0px;
    font-weight: $light-weight;
}

#event-wrapper {
    margin: 1%;
    padding: 0px;
    width: 98%;
}

#event-wrapper .form {
    width: 98%;
    margin: 0 auto;
}

#event-wrapper .home-tag {
	font-size:18px;
	text-align:center;
}

#event-wrapper h1,#map-title {
    font-size: $larger-font;
    padding: 10px 0px;
}

#login-title {
	font-size:$normal-font;
	font-weight:300;
	padding:15px;
	margin-top:0px;
	width:95%;
	margin-left:2.5%;
	margin-right:2.5%;
	margin-bottom: -15px;
}

#event-going {
    position: absolute;
    top: -220px;
    left: 5px;
    color: $white-color;
    padding: 7.5px 10px;
    background: rgba(0,0,0,.5);
    text-align: center;
    min-width: 50px;
    border-radius: 5px;
}

#event-going span {
    font-size: $large-font;
}

#nt-price {
    font-size:12px !important;
    color:#c2c8cf;
}

#event-detail-rating {
    position: absolute;
    top: -300px;
    right: 0px;
    padding: 10px 15px 12.5px 15px;
    color: $white-color;
    background: rgba(0,0,0,.5);
}

#inner-event-detail-rating {
	font-size: $larger-font;
    font-weight: bold;
}

#map-title {
    width: 96%;
    padding: 0px;
    margin-left: 2%;
    margin-right: 2%;
    margin-top:20px;
}

#intercom-container .intercom-launcher-button {
	background-color:$primary-color !important;
	border:0px !important;
}

#intercom-container .intercom-launcher {
	bottom:60px !important;
}

.leaflet-container a {
    color: $primary-color !important;
}

.cropit-preview {
  width: 300px;
  height: 300px;
}
.cropit-preview.cropit-image-loaded .cropit-preview-image-container {
  cursor: move;
}

#searchMaps {
	width:90%;
	margin-left:5%;
	margin-right:5%;
	margin-top:10px;
	margin-bottom:10px;
}

#map-nav {
    height: 30px;
    margin-top: -12px;
    margin-bottom: -12px;
}

#event-wrapper .result-crumb {
	font-size:12px;
    margin-left:1%;
}

.ui.secondary.pointing.menu {
	border:0px;
}

.ui.secondary.pointing.menu .item {
	font-size: $large-font;
	display: block;
    text-align: center;
    border:0px;
    border: 0px;
    color: $gray-color;
}

#split-50 .ui.secondary.pointing.menu .item {
	border:0px;
}

.ui.styled.accordion, .ui.styled.accordion .accordion {
	box-shadow:none;
}

.ui.styled.accordion .accordion .title, .ui.styled.accordion .title {
	border-top:0px;
	text-transform: capitalize;
    letter-spacing: normal;
    font-size: $large-font;
}

.ui.styled.accordion li a {
	font-size:14px;
}

#city-list, #map-list {
    position: fixed;
    top: 0px;
    bottom: 0px;
    right: auto;
    left: 0px;
    width: 50%;
    margin-top: 130px;
    padding: 0px;
    float: none;
    overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
    border:0px;
}

.map-name .map-type {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 60px;
}

.map-name .map-type i {
	font-size:50px;
	color:$white-color;
}

#myLargeModalCTA {
	width:100%;
    border-radius: 5px;
    border-bottom: 3px solid rgba(255,255,255, .5);
    margin-bottom:15px;
}

#myLargeModalImage:hover {
	cursor:pointer;
}

#favoriteCTA {
	padding: 10px;
	width:100%;
	border: 1px solid $secondary-color;
    text-transform: uppercase;
}

.modal-content {
	border-radius: 0px;
}

.map-name h3{
    color: $white-color;
    font-weight: $normal-weight;
    font-size: $larger-font;
}

.map-name p {
	text-transform: none;
    text-align: left;
    padding: 15px 5px 5px 0px;
    font-weight: $light-weight;
    font-size: $large-font;
    height: 150px;
}

.navbar-brand {
	font-size:14px;
}

.map-navigation {
	 width: 30%;
    height: 100%;
    position: fixed;
    top: 57px;
    left: 0px;
    overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
    box-shadow: 0px 2px 3px $secondary-color;
    background: $off-white-color;
    padding-bottom:100px;
    display:block;
}

.secondary-map-layout {
	height:150px !important; 
	width:100% !important; 
	top: 55px; 
	z-index: -1;
}

.secondary-map-cover {
	width:100% !important; 
	height:150px !important; 
	position:relative; 
	margin-top:-150px;
}

.secondary-wrapper {
	width:100%; 
	position:relative; 
	margin-top: 100px; 
	padding-bottom: 100px;
}

.secondary-wrapper p {
    color:#000;
}

.home-map-layout {
    height: 350px !important;
}

.home-map-cover {
    height: 300px !important;
    margin-top: -55px;
    background: rgba(42,45,51,.85) !important;
    width: 100% !important;
    position:absolute;
}

.secondary-prof-spacing {
	margin-top:-25px;
	text-align: left;
}

.secondary-region-spacing {
	margin-top:-150px;
	text-align: left;
}


.secondary-edit-spacing {
	text-align: left;
    width: 60%;
    margin: 0 auto;
}

#region-desc {
	width:90%;
    text-align: left; 
	margin:0 auto;
    color:#000;
}

.home-page-head {
    padding:275px 0px;
    margin-top: 0px;
    background: $secondary-color;
    background-position-y: 50%;
}

.home-page-head.secondary {
    background: $white-color;
    background-size:cover;
}

.home-page-head.explore {
    background:url('../../img/discover7.jpeg');
    background-size:cover;
    padding: 200px 100px;
    margin-top: 55px;
}

.home-page-head.explore h2 {
    font-size: $larger-font;
    font-weight: $light-weight;
}

.home-page-head.explore p {
    font-size: $large-font;
    font-weight: $light-weight;
}

.home-page-head .intro h2 {
    font-size: 60px;
    color:$white-color;
    font-weight: $bold-weight;
}

.home-page-head .intro p {
    font-size: 24px;
    width: 55%;
    margin:0 auto;
    color:$white-color;
    font-family: $primary-font;
    font-weight: $bold-weight;
    letter-spacing: 0.5em;
    text-indent: 0.5em;
    font-weight: 700;
    text-transform: uppercase;
}

#baseClick a {
    font-family: $primary-font;
    font-weight: $bold-weight;
    letter-spacing: 0.1em;
    text-indent: 0.5em;
    font-weight: 700;
    text-transform: uppercase;    
}

.page-page-head {
    padding:200px 0px;
    margin-top: 0px;
    background-color: $secondary-color;
}

.page-page-head .intro {
    position:relative;
    left:100px;
}

.page-page-head .intro h2 {
    font-size: 60px;
    color:$white-color;
    font-weight: $bold-weight;
}


.page-page-head .intro p {
    font-size: 25px;
    font-weight: 300;
    width: 70%;
    padding-bottom: 25px;
    color: $white-color;
}

.home-page-head .intro .btn-alt, .page-page-head .btn-alt {
    color: $white-color;
    border-color: $white-color;
}

.home-page-head .intro h2 span {
    color: $primary-color;
    border-bottom: 1px dashed $primary-color;
}

.sell-head {
    text-align: center;
    padding: 125px 0px 50px 0px;
    background: $secondary-color;
    color: $white-color;
}

.sell-head div {
    text-align: center;
}

.sell-head p {
    font-size: $large-font;
    font-weight: $light-weight;
}

.services-box {
    background: $off-white-color;
    color: $secondary-color;
    box-shadow: 0px 2px 3px $gray-accent-color;
    color: $white-color;
    width: 100%;
    margin: 0 auto;
}

.services-box .inner-sales-box {
    color: $secondary-color;
    padding: 20px 30px 10px 30px;
    height: 300px;
}

.services-box .inner-sales-box h2 {
    text-align: left;
    margin-bottom: 0px;
}

.services-box .services-price {
    color: #42ce6d;
    font-weight: 400;
    float: right;
    font-size: 20px;
    position: absolute;
    top: 25px;
    right: 25px;
}

.landing-screenshot {
    margin-top:-75px;
}

.landing-screenshot p {
    font-size: $large-font;
    font-weight:300;
    padding:0px 0px 10px 0px;
    width:80%;
    margin: 0 auto;
}

.landing-screenshot .icon {
    color: $primary-color;
} 

.box-o-content {
    width: 80%;
    margin: 0 auto;
    position: relative;
    padding: 100px 0px;
    border-radius: 5px;
}

.box-o-content h3 {
    font-size: $large-font;
}

.box-o-content .mid-range {
    padding:50px 0px;
}

.page-head {
    padding: 200px 0px;
    background: $secondary-color;
    color: $white-color;
}

#map-making-loader, .map-making-loader {
	height: $normal-font; 
	float: right;
}

.page-head.secondary {
	background:url('../../img/discover13.jpeg');
    background-size:cover;
}

.page-head h2, .landing-page-head h2 {
    font-size: 60px;
    font-weight:bold;
    color: $white-color;
}

.page-head p, .landing-page-head p {
	font-size:$larger-font;
	font-weight:300;
    margin:0 auto;
}

.home-padding {
    padding: 0px 0px 75px 0px;
}

.jobs-section .panel-title a {
    color: $primary-color;
    font-size: $large-font;
}

.jobs-section .panel-collapse h3 {
	font-size:24px;
}

.jobs-section .panel-collapse h4 {
	font-size:$normal-font;
	font-weight:bold;
}

.banner {
    padding: 150px 0px;
}

.banner h2 {
	font-size: 50px;
    font-weight: $light-weight;
}

.banner p {
	font-size: $normal-font;
    font-weight: $light-weight;
    line-height: 1.6;
}

.banner .pad-text {
	padding:75px;
}

.banner img{
	width:100%;
}

.banner.type-1 {
	background:$white-color;
}

.banner.type-2 {
	background:#efefef;
}

.banner.type-3 {
    background: $secondary-color;
    color:$white-color;   
}

.banner.type-3 h2 {
	font-size: $larger-font;
}

.banner.type-3 .inner-wrap {
	padding:75px;
}

.leads {
    list-style-type: none;
    text-align: center;
    font-size: $large-font;
    padding: 50px 0px 0px 0px;
}

.leads li {
    display:inline-block;
    padding: 0 30px;
}

#map-detail-view .details-title {
	padding: 15px 0px;
    font-size: $larger-font;
    font-weight: bold;
}

#map-detail-view .back-btn {
    margin-bottom: -50px;
    position: relative;
    top: 10px;
    left: 10px;
    line-height: 30px;
    background-color: $white-color;
    text-align: center;
    color: $secondary-color;
    font-size: 20px;
    border-radius: 2px;
    padding: 10px 17.5px;
}


.home-marketing-1 {
	padding:15px 50px 15px 50px;
}

.home-marketing-1 .col-md-4{
	margin-top:25px;
}

i.search.icon {
    -webkit-text-stroke: 1px $primary-color;
}

.new-lang-search {
    font-size: $larger-font;
    background: rgba(0,0,0,.5);
    margin: 0px -150px;
    padding: 50px 150px 125px 150px;
}

.new-lang-explore-search {
    float: left;
    padding: 0px;
    margin: 0px;
    font-size:14px;
    margin-top:4px;
    margin-left:4px;
}

.new-lang-explore-search .ui.search>.results .result .title {
    font-size:14px;
}

.new-lang-explore-search .ui.search>.results .result .description {
    font-size:12px;
}

.ui.form input:not([type]), .ui.form input[type=text], .ui.form input[type=email], .ui.form input[type=search], .ui.form input[type=password], .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=tel], .ui.form input[type=time], .ui.form input[type=url], .ui.form input[type=number] {
    font-size: $normal-font;
}

.ui.popup {
    font-size: 1.25rem;
}

.ui.selection.dropdown .menu>.item {
    font-size:$normal-font;
}

.new-lang-search .inner-lang {
    font-size:24px;
    padding:10px;
    color:$white-color;
    text-transform: uppercase;
} 

.new-lang-search a.result:hover {
    text-decoration: none;
}

.new-lang-search .button, .new-lang-search .button:hover {
    background: $primary-color;
    color: $white-color !important;
    font-size: 20px;
    font-weight: $light-weight;
    border: 0px;
    float: left;
    border-radius: 0px 5px 5px 0px;
    padding: 22.5px 30px !important;
}

.new-lang-explore-search .button, .new-lang-explore-search .button:hover {
    padding: 5px 5px 5px 17px !important
}

.new-lang-search .button:hover {
    opacity:.75;
}

.new-lang-search .content {
    margin: 0 auto;
    text-align: left;
    width: 100%;
    font-size: $large-font;
}

.new-lang-search .ui.search>.results .result .title, .new-lang-search .ui.search>.results .result .description, .new-lang-search.ui.search>.results>.message .header, .new-lang-search .ui.search>.results>.message .description {
    border-bottom:0px;
    padding-left:0px;
    text-transform: capitalize;
    letter-spacing: normal;
    padding-bottom: 0px;
}

.new-lang-search .ui.search>.results .result {
    padding:.3em .6em;
}

.new-lang-search .ui.selection.dropdown .menu {
    max-height:12em !important;
}

.new-lang-search .ui.selection.dropdown {
    border-radius: 0px !important; 
    border-right: 0px;
}

#home-new-lang-search .ui.search .prompt {
    border-radius:0px;
    font-size: $large-font;
    font-weight: $light-weight;
    height:65px;
}

#home-new-lang-search .ui.dropdown.selection {
    height: 65px;
    max-height: 65px;
    font-size: $large-font;
    font-weight: $light-weight;
}

#home-new-lang-search .default.text {
    position: relative;
    top: 5px;
}

#home-new-lang-search .ui.dropdown > .text {
    position: relative;
    top: 5px;
}

#emailModal {
    margin-top: 0!important;
    top: 150px!important;
    height: 380px!important;
}

#emailModal>.close {
    cursor: pointer;
    position: absolute;
    top: 0.5rem;
    right: 1.15rem;
    z-index: 1;
    opacity: .8;
    font-size: 2em;
    color: #000;
    -webkit-text-stroke: 2px white;
}

.ui.dimmer {
    z-index:10000;
    background-color: rgba(0,0,0,.65);
}

#emailModal>.header:not(.ui) {
    font-size:24px;
    text-transform: uppercase;
    font-weight:300;
}

#emailModal p {
    font-size:$normal-font;
    color: $primary-color;
    font-weight:300;
}

#emailModal label {
    font-size:14px;
}

#emailModal .btn-primary {
    background: $red-color;
}

#success-text h2 {
    padding:10px 0px;
    font-size: 30px;
    color: $primary-color;
    font-weight: $light-weight;
}

#success-text .ui.checkbox {
    display:block;
    text-align: left;
    padding:2.5px 0px;
}

#success-text .col-md-12 {
    padding:0px 0px 15px 0px;
}

#success-text .ui.checkbox label {
    font-size:20px;
}

#success-text .ui.checkbox input[type=checkbox], #success-text .ui.checkbox input[type=radio]{
    left: 20px;
    bottom: 18px;
    margin-top: 0px;
}

.filter-btn {
    background: $red-color;
    border: 0px;
    color: $white-color;
    font-size: $small-font;
    border-radius: 5px;
    padding: 5px 15px;
}

.new-lang-search .action {
    float:left;
}

.new-lang-search .search {
    float:left;
}

.new-lang-explore-search .ui.selection.dropdown {
    min-width: inherit;
}

.new-lang-explore-search .ui.search .prompt {
    border-radius: 0px;
    height: 100%;
    min-width: inherit;
}

.new-lang-explore-search .ui.selection.dropdown {
    letter-spacing: normal;
    text-transform: capitalize;
    min-width: 200px;
}

.home-marketing-1 img {
	height:150px;
}

.home-marketing-1 h2 {
	font-size:32px;
	padding:25px 0px 25px 0px;
	font-weight:300;
}

.home-marketing-1 h3 {
	font-size:26px;
}

.home-marketing-1 p {
	padding:25px;
	font-size:20px;
	font-weight:300;
}

.home-marketing-1 a, .home-marketing-1 a:hover, .home-marketing-1 a:focus {
	letter-spacing: 2px;
	border-bottom: 1px dotted $gray-color;
	padding-bottom:5px;
	font-size:18px;
	text-decoration: none;
}

.home-marketing-2 h2 {
	margin-top:125px;
	margin-bottom:25px;
}

.home-marketing-2 p {
	font-size:24px;
	font-weight:300;
}

.home-marketing-2 small {
	font-size:20px;
}

.home-marketing-2 .item {
	padding-bottom:25px;
}

.home-signup {
	margin-top: 25px;
    position: relative;
    left: 90px;
}

.branded {
    height: 35px;
    margin-top: -8.5px;
}

.branded-home {
    height: 40px;
    margin-top: -15px;
    position: relative;
    right: 6px;
}

.branded-pages {
	height: 40px;
    margin-top: -15px;
}

.home-title-name {
    color:$white-color;
}

.user-prof-name, .home-title-name {
	font-size: $larger-font;
    font-weight: $normal-weight;
    margin-top:-25px;
}

.home-sort-bar {
     margin-top:65px;
 }


.user-stats {
    font-size: $large-font;
    width: 40%;
    margin: 0 auto;
    padding-top: 10px;
}

.user-stats .stat-wrap {
    width: 33.33%;
    float: left;
    font-size:$normal-font;
}

.modal-stat {
	cursor:pointer;
}

#mapsCount, #followersCount, #followingCount {
	font-weight:bold;
}


.follow-list h2 {
	font-size:14px;
	font-weight:bold;
	margin:0 auto;
}


.branded-dashboard {
	height:30px;
	margin-top:-10px;
}

#view {
	font-size: $small-font;
	padding: 3px 30px;
	margin-top: -4px;
	float: right;
	font-weight: $normal-weight;
	letter-spacing: 2px;
}

.themed {
	padding:50px;
	text-align: left;
}

.main-tagline {
    padding: 75px 0px 0px 0px;
    font-size: $largest-font;
    font-weight:400;
}

#home-search {
    width: 55%;
    margin: 0 auto;
    padding:100px 0px 175px 0px;
}

#download-cta {
	min-height: 300px;
}

#download-cta h2 {
	font-size:24px;
	font-weight:300;
}

.st-ui-search-input, .st-default-search-input {
    display: inline-block;
    width: 190px;
    height: $normal-font;
    padding: 7px 11px 7px 28px;
    border: 1px solid #bbb;
    border: 1px solid rgba(0,0,0,0.25);
    font-weight: $normal-weight;
    color: #3A444F;
    font-size: $normal-font;
    line-height: $normal-font;
    box-sizing: content-box;
    background: $white-color 8px 8px no-repeat url('../../img/search-icon.png');
    background-clip: padding-box;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

#base-search {
	position: relative;
    top: 7px;
    left: 10px;
    width: 400px;
    font-weight: $light-weight;
    display: inline-block;
}

#explore-search {
    width: 100%;
    margin: 0 auto;
    font-size:14px;
    margin-top:0px;
}

#explore-search .ui.selection.dropdown .menu>.item {
    font-size: $large-font;
    font-weight: $light-weight;
    letter-spacing: normal;
}

#explore-search .btn {
    width: 15%;
    height: 33px;
    float: right;
    font-size: $small-font;
    font-weight: $light-weight;
    text-transform: capitalize;
}

#explore-search .ui.fluid.dropdown {
    height: 30px;
    width: 100%;
    border:0px;
}

#explore-search .ui.fluid.dropdown.home-select {
	font-size:12px;
}

#explore-search .ui.search.selection.dropdown>input.search, #explore-search .ui.search.selection.dropdown>input.search {
	color:$secondary-color;
}

#explore-search .ui.search.dropdown>.text {
	color:$gray-color;
	font-weight:300;
	margin-top:-2px;
	text-transform: none;
	letter-spacing: normal;
}

#featured-spaces a {
    color: $primary-color;
}

#featured-spaces .mix{
	display: none;
}

#featured-spaces .home-photo-link a, #map-spaces .home-photo-link a{
	color:$secondary-color;
	font-weight: $normal-weight;
	text-decoration: none;
}

#featured-spaces h3:hover, #featured-spaces a:hover, #map-spaces h3:hover, #map-spaces a:hover {
	text-decoration: none;
}

#featured-spaces .home-photo-link a:hover, #map-spaces .home-photo-link a:hover {
	text-decoration: none;
}

.search-this-area {
    z-index: 100021;
    background: $white-color;
    padding: 10px 15px;
    font-size: $small-font;
    font-weight: 300 !important;
    border-radius: 5px;
    border: 1px solid $gray-accent-color;
    position: relative;
    top: 15px;
    display: none;
    font-weight: bold !important;
    box-shadow: 0px 2px 3px $gray-accent-color;
}

.map-home-title {
    font-size: $larger-font; 
    font-weight: $normal-weight;
    margin-top:60px !important;
}

.margin-none {
    margin-top:0px !important;
}

#home-search div {
	background:$white-color;
	font-size: 20px;
    font-weight: $light-weight;
}


#region {
    width: 35%;
    float: left;
    height: 60px;
    border-radius: 0px;
    border:0px;
    text-transform: capitalize;
}

#region-title {
    font-size: $large-font;
    z-index: 999;
    position: relative;
    top: -90px;
    text-shadow: 0px 2px 3px $secondary-color;
    color: $white-color;
 }

 .region-note {
    color: gray; 
    font-size: $small-font; 
    padding: 25px 0px 0px 0px; 
    width: 90%; 
    text-align: left; 
    margin: 0 auto;
 }

.home-tagline {
    width: 100%;
    padding: 15px 0px;
    font-size: 30px;
    line-height: 44px;
}

.ui.fluid.dropdown {
    width: 50%;
    float: left;
    border: 0px;
    top: 0px;
    border-right: 1px solid $gray-color;
    border-radius: 0px;
    height:60px;
}

.ui.selection.dropdown .menu>.item {
	padding: 15px 10px !important;
}

.ui.search.dropdown .menu {
    max-height: 18em;
}

.ui.fluid.dropdown.home-select {
	background: $white-color;
    color: $secondary-color;
    width: 35%;
    font-size: $large-font;
    font-weight: $light-weight;
    border:0px;
}

.ui.cards>.card {
	float: left;
    width: 46.5%;
    border-radius:0px;
}

.ui.card>.content>.header:not(.ui), .ui.cards>.card>.content>.header:not(.ui){
	font-size: 1.0em;
}

.ui.card .meta, .ui.cards>.card .meta {
    font-size: .8em;
}

.ui.card>.content, .ui.cards>.card>.content {
	width:100%;
}

.login-title {
	letter-spacing: 2px;
	text-transform: uppercase;
}

.login-form .form-control {
	font-size: $large-font;
	padding: 10px;
	font-weight: $light-weight;
	box-shadow: none;
	color: $secondary-color;
}

.explore-helper {
	font-size:24px; 
	color:$secondary-color; 
	padding:15px; 
	width:50%; 
	margin:0 auto;
	font-weight:300;
}

.top-search {
	width:260px; 
	float:right; 
	position:relative; 
	right:260px; 
	margin-top:-4px; 
	margin-bottom:-20px;
}

.location-wrap-sm {
  margin: 25px 0px;
  color:$secondary-color;
}

div#location-wrap-sm .col-md-12 {
    padding: 0px;
}

.location-wrap-sm .col-md-6 {
	padding:0px 5px;
}

#results-loader {
	margin-top:150px;
}

#results-loader h3 {
	font-size:24px;
	font-weight:300;
}

.location-wrap-sm .primer {
	font-size:$small-font;
}

.location-wrap-sm h3 {
    font-size: $normal-font;
    font-weight: $normal-weight;
}

.location-map-title {
    background: $white-color;
    padding: 15px 10px;
    margin-top: -5px;
    margin-bottom: 15px;
    box-shadow: 0px 2px 3px $light-gray-color;
}

.location-map-title h3 {
	font-size:$normal-font;
}

.location-map-title h5 a {
	font-size:$normal-font;
	color: $primary-color;
	font-weight:400;
}

.location-map-title a {
    font-weight: $bold-weight;
}

.inner-item-wrap:hover {
	opacity:.9;
	cursor: pointer;
}

.location-wrap{
  background: $secondary-color;
  background-size:cover;
  background-position: 50% 50%;
  margin: 5px 0px;
  color:$white-color;
}

.inner-map-wrap {
	padding:100px 0px; 
	background:rgba(42,45,51,.0);
}

.inner-map-wrap:hover {
	background: rgba(42,45,51,.9);
}

.mix {
    padding: 0px 4px;
    display: table-cell;
    float: none;
    width:49%;
}

.mix-2 {
     padding: 0px 4px;
     display: table-cell;
     float: none;
     width:24%;
}

.tiny {
	font-size:10px;
}

.filter-caret {
	float: right;
    position: relative;
    top: 9px;
    right: 15px;
}

.location-wrap h3 {
	font-size: 20px;
    font-weight: $normal-weight;
    text-transform: capitalize;
    text-align: center;
}

.inner-item-wrap:hover {
	opacity:.75;
	cursor: pointer;
}

.location-wrap .cat-title {
	text-transform: uppercase;
	font-size:$normal-font;
	font-weight: bold;
}

.location-image {
	width:100%;
    width: 100%;
    height: 300px;
    box-shadow: 0px 2px 3px $gray-accent-color;
    -webkit-box-shadow: 0px 2px 3px $gray-accent-color;
    -moz-box-shadow: 0px 2px 3px $gray-accent-color;
}

.modal-title {
	font-size: $larger-font;
    padding-left: 30px;
    font-weight: $light-weight;
}

.modal-body p#myLargeModalDescription {
    font-size: $large-font;
    line-height: 24px;
}

.modal-header .close {
    font-size: 30px;
}

.modal {
	background: rgba(0,0,0,.6);
	z-index: 100021;
}

.marker-details {
	padding:5px 0px;
    font-size: $large-font;
    font-weight: $light-weight;
}
.price-plan {
    padding: 15px 0px;
    border: 0px;
    font-size: 12px;
    color: gray;
    font-weight: bold;
    text-transform: uppercase;
}

.price-plan h4 {
    margin-top:0px;
}

.title-labels {
    padding: 0px 0px 10px 0px !important;
    border: 0;
    font-size: 15px !important;
    margin-bottom: 5px;
    border-bottom: 0px !important;
    font-weight:bold;
}

.map-title-name {
    padding: 0px !important;
    margin: 0px !important;
    position: relative;
    top: 11px;
    left: 25px;
    font-size: 20px !important;
    font-weight: $normal-weight;
}

.toggled-on {
	float: right;
    position: absolute;
    top:35px;
    right:25px;
}

.toggled-on img {
	height:40px;
	width:40px;
}

.down-arrow img {
	height:40px;
	width:40px;
}

.gray {
	color: $gray-color;
}

.darkgray {
	color:#333;
}

#featured-spaces, #map-spaces {
	padding-bottom:100px;
}

#featured-spaces a:hover, #map-spaces a:hover{
	text-decoration: none;
}

.city-sorter .filter.active {
	border:1px solid $primary-color;
	color:$primary-color;
    border-radius: 5px;
}

.city-sorter .filter.active {
	color:$primary-color;
}


.city-sorter .filter:hover {
	border:1px solid $primary-color;
	color:$primary-color;
    border-radius: 5px;
	cursor: pointer;
}

.city-sorter .filter {
    display: inline-block;
    padding: 5px 15px;
    border: 1px solid $gray-color;
    border-radius: 5px;
    margin-right: 1%;
    text-shadow: none;
    font-size: $normal-font;
    font-weight: $light-weight;
    margin-bottom: 25px;
}

.city-sorter-2 {
	border-top:1px solid #efefef;
	margin-top:15px;
	padding-top:10px;
}

.city-sorter-2 .active-city {
	color:$primary-color;
    border-radius: 5px;
    font-weight:bold;
}

.city-sorter-2 .active-city a {
	color:$primary-color;
}

.city-sorter-2 li {
    display: inline-block;
    padding: 20px 25px;
    width: 13%;
    border-radius: 5px;
    margin-right: 1%;
    text-shadow:none;
    font-size:14px;
    font-weight:300;
}

.back-to {
	background: $white-color;
    padding: 6px 12px;
    position: relative;
    top: 60px;
    border-radius: 0px 5px 5px 0px;
    font-weight: $light-weight;
    font-size: $large-font;
    border-top: 1px solid rgba(34,36,38,.15);
    border-right: 1px solid rgba(34,36,38,.15);
    border-bottom: 1px solid rgba(34,36,38,.15);
}

.map-selection {
    border: 0px;
    border-radius: 0px;
    height: 250px;
}

.map-selection h3 {
	position: relative;
    top: 50%;
    font-size: 30px;
    color: $white-color;
}

.marker-popup:hover {
	cursor:pointer;
}

.marker-popup a {
	color:$secondary-color !important;
}

.marker-popup a:hover, .marker-popup a:visited {
	color:$secondary-color !important;
	text-decoration: underline;
}

.marker-popup h1 {
	color:$secondary-color;
	font-size: $large-font; 
	padding: 10px;
}

.ui-button {
    background: $gray-color;
    color: $white-color;
    display: block;
    position: fixed;
    top: 80px;
    right: 10px;
    text-align: center;
    padding: 10px;
    border-radius: 0px;
    text-decoration: none;
}

.ui-button:hover, .ui-button:active, .ui-button:visited {
    opacity:.8;
    color:$white-color;
    text-decoration: none;
}


.download-wrap {
    text-align: center;
    padding: 100px 0px 250px 0px;
    background: url('../../img/bg-teams.png');
    background-size: cover;
    position: relative;
    top: 20px;
}

.wrap-more {
    margin-top: 25px;
    margin-bottom: -25px;
}

.type-wrapper {
	font-size:24px;
	font-weight:300;
	padding:15px 0px;
}

#map {
	height: 100%;
	width:70%;
}

#insights-map > .mapboxgl-canvas {
    height: 600px !important;
    width: 800px !important;
}

#home-map {
	top: 0px;
    left: 0px;
    width: 100% !important;
    z-index: -99999;
}

#baseMap {
	height: 200px;
	margin-top:0px;
}


#learn-more {
	font-size:32px;
	color:$white-color;
	-webkit-animation: myfirst 1.5s;
	animation: myfirst 1.5s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-play-state: running;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-direction: alternate;
	-webkit-animation-play-state: running;
	webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes myfirst {
    from {-webkit-transform: translate(0px,0px);}
    to {-webkit-transform: translate(0px,10px);}
}

/* Standard syntax */
@keyframes myfirst {
    from {transform: translate(0px,0px);}
    to {transform: translate(0px,10px);}
}

.dash-data {
	text-align: center;
	width:32%;
	margin-right:1%;
	border:0px;
	padding: 15px 0px;
	box-shadow: none;
}

.dash-data h4{
	text-transform: uppercase;
	font-size:20px;
	font-weight:300;

}

.dash-data h2{
	font-size:36px;
	font-weight:300;
}

.dash-1 {
	color: $primary-color;
}

.dash-2 {
	color:#5cb85c;
}

.dash-3 {
	color:#a94442;
}

.account-icon {
	height: 25px;
	width: 25px;
	margin-right: 10px;
	margin-top: -10px;
	margin-bottom: -10px;
}

.progress {
    border-radius:0px;
}

.progress-bar {
    background-color: $primary-color;
}

.the-leads .map-home-title {
    font-size: 30px; 
    padding: 20px 0px 40px 0px;
}

.the-leads .more-info p {
    font-size:$normal-font;
    width:75%;
    margin:0 auto;
}

.view-offer {
    font-size:30px;
    font-weight:300;
}

.click-to-call {
    width: 50%; 
    text-align: 
    right; color:$white-color; 
    padding-right:25px !important; 
    margin-top:2px !important;
}

.mob-click-to-call {
    display:none;
}

.verified-secure {
    margin:50px 0px;
}

.form-control {
    border-radius: 0px;
    padding: 15px 10px;
    border: 0px;
    box-shadow: none;
    border-bottom: 2px solid #eee;
    color: $secondary-color;
}

.add-image {
	width: 50%;
    font-size: $small-font;
    padding: 10px 0px;
}

.stripe-label {
    position: relative;
    height: 40px;
    line-height: 40px;
    margin-left: 0px;
    padding: 0px;
    display: block;
}

.stripe-field {
    background: transparent;
    border: 0;
    outline: none;
    cursor: text;
    width: 100%;
    height: 40px;
    float: right;
    line-height: 40px;
    font-weight: $normal-weight;
    font-size: $normal-font;
}

.stripe-error {
    display: none;
    font-size: 15px;
}

.stripe-error.visible {
    display: inline;
}

.stripe-error {
    color: $red-color;
}

.price-left {
	border-left:1px solid $off-white-color;
}

#pricing-spaces h4 {
    margin-top: 0px;
    position:relative;
    top:-20px;
}

.pricing-box {
    min-height: 500px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    top: -100px;
}

#pricing-segment .active.item {
    background: $secondary-color;
    color:$white-color;
    border-radius: 25px;
    padding: 15px 50px !important;
    border:0px;
    z-index:100;
    box-shadow: 0px 2px 3px $gray-accent-color;
}

#pricing-segment .item {
    background: $off-white-color;
    padding: 14px 44px !important;
    border-radius: 25px;
    color: $secondary-color;
    border: 1px solid $gray-accent-color;
    float: left;
    width: 50%;
}


.table-pricing h4 span {
    color: $primary-color;
}

.table-pricing th {
    width:15%;
    font-weight: 400;
    color: gray;
}

.inverse {
	border:2px solid $white-color;
	color:$white-color;
}
.inverse:hover {
	border:2px solid $white-color;
}

.well:hover {
	opacity:.9;
}

.well .text-muted {
	color: $secondary-color;
	font-weight: $light-weight;
	font-size: 20px;
}

.co1 {
	background:#a94442;
	color:$white-color;
}

.co2 {
	background:#8a6d3b;
	color:$white-color;
}

.co3 {
	background:#5bc0de;
	color:$white-color;
}

.co4 {
	background:$primary-color;
	color:$white-color;
}

.co5 {
	background:#5cb85c;
	color:$white-color;
}

.co6 {
	background:#2b2d35;	
	color:$white-color;
}

.edit {
	margin: 20px 0px 20px 20px;
}

.account-head {
	background-color: $white-color;
	border:0px;
	color:$secondary-color;
	box-shadow:none;
	margin-top:-20px;
	text-align:left;
	float:left;
}

.well.account-head {
	cursor: default;
	padding: 0px;
	margin-top: 0px;
}

.account-head h3 {
	font-size: $larger-font;
	font-weight: $light-weight;
	color: $secondary-color;
	border-bottom: 1px dotted $gray-color;
	padding-bottom: 5px;
}

.account-head h4 {
	font-size:$large-font;
	font-weight:300;
}

.checking-out {
	list-style-type: none;
	padding: 25px;
	margin-top: 30px;
	border:1px solid $gray-color;
}

.checking-out li {
	display:inline;
	padding:10px 15px;
	font-size:14px;
	font-weight:400;
	color:#777;
}

.checking-out .active {
	font-weight: $normal-weight;
	color: #5cb85c;
	padding: 15px;
}


.price {
	font-weight:300;
	font-size:30px;
	color:#5cb85c;
}

.table-assignments {
	border-style:solid; 
	border-width: 1px; 
	border-color:#d3d3d3; 
	padding-bottom:20px; 
	margin:20px;
	text-align:center;
}

.add-new-box {
	border: 1px dashed $gray-color;
	padding: 15px 0px;
}

.add-new-box h3 {
	font-size:80px;
	font-weight:300;
}


.box-link:hover, .box-link:focus {
	text-decoration: none;
	color:$secondary-color;
}

.add-new-box h4 {
	font-weight:300;
}

.add-new-box:hover {
	background: $off-white-color;
	text-decoration: none;
}

.invitee-toggle {
	font-size: 18px;
	font-weight: $light-weight;
}

.title {
    padding: 0 4px 15px 4px;
    font-size: $large-font;
    color: $secondary-color;
    border-bottom: 1px solid $secondary-color;
    display: inline-block;
    vertical-align: middle;
}

.wrap-head {
	background-color: $white-color;
	min-height: 250px;
	padding: 50px 0;
}

.wrap-close {
	background-color: $white-color;
	min-height: 250px;
	padding: 50px 0;
}

.wrap-close .btn {
	font-size:24px;
	letter-spacing: 2px;
	text-transform: uppercase;
	font-weight:300;
	padding:20px 40px;
}

.wrap1 {
    height: 970px;
    padding: 50px 0px;
}

.wrap2 {
	background-color: $white-color;
	min-height: 725px;
	padding: 50px 0px;
}

.wrap3 {
	background-color: $white-color;
	padding: 50px 0px;
	min-height:950px;
}

a.dropdown-toggle.account {
	text-transform: capitalize;
}

.codrops-header {
    margin: 0 auto;
    padding: 4em 1em;
    text-align: center;
}

.codrops-header h1 {
    margin: 0;
    font-weight: 800;
    font-size: 4em;
    line-height: 1.3;
}

.codrops-header h1 span {
    display: block;
    padding: 0 0 0.6em 0.1em;
    color: #74777b;
    font-weight: $light-weight;
    font-size: 45%;
}

.codrops-demos {
    clear: both;
    padding: 1em 0 0;
    text-align: center;
}

.content + .codrops-demos {
    padding-top: 5em;
}

.codrops-demos a {
    display: inline-block;
    margin: 0 5px;
    padding: 1em 1.5em;
    text-transform: uppercase;
    font-weight: bold;
}

.codrops-demos a:hover, .codrops-demos a:focus, .codrops-demos a.current-demo {
    background: #3c414a;
    color: $white-color;
}

.codrops-top {
    width: 100%;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 0.69em;
    line-height: 2.2;
}

.codrops-top a {
    display: inline-block;
    padding: 1em 2em;
    text-decoration: none;
    letter-spacing: 1px;
}

.codrops-top span.right {
    float: right;
}

.codrops-top span.right a {
    display: block;
    float: left;
}

.codrops-icon:before {
    margin: 0 4px;
    text-transform: none;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    font-family: 'codropsicons';
    line-height: 1;
    speak: none;
    -webkit-font-smoothing: antialiased;
}

.codrops-icon-drop:before {
    content: "\e001";
}

.codrops-icon-prev:before {
    content: "\e004";
}

.related {
    clear: both;
    padding: 6em 1em;
    font-size: 120%;
}

.related > a {
    display: inline-block;
    margin: 20px 10px;
    padding: 25px;
    border: 1px solid #4f7f90;
    text-align: center;
}

.related a:hover {
    border-color: #39545e;
}

.related a img {
    max-width: 100%;
    opacity: 0.8;
}

.related a:hover img, .related a:active img {
    opacity: 1;
}

.related a h3 {
    margin: 0;
    padding: 0.5em 0 0.3em;
    max-width: 300px;
    text-align: left;
}

@media screen and (max-width: 25em) {
    .codrops-header {
        font-size: 75%;
    }

    .codrops-icon span {
        display: none;
    }
}

.grid {
    margin: 0 auto;
    padding: 15px 10px;
    list-style: none;
    text-align: center;
}

.grid .hover-title {
    font-size: $small-font;
    z-index: 1000000;
    color: $secondary-color;
    position: relative;
    background: $white-color;
    width: 100%;
    margin: 0 auto;
    padding: 10px 0px;
    border: 2px solid $gray-accent-color;
    text-align: left;
    padding-left: 10px;
}

.grid .hover-title:hover {
    border: 2px solid $primary-color;
    color: $primary-color;
    box-shadow: 0px 2px 3px $gray-accent-color;
}

.grid figure {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 10px 2%;
    max-height: 190px;
    width: 44%;
    background: $secondary-color;
    text-align: center;
    cursor: pointer;
}

.grid figure img {
    position: relative;
    display: block;
    min-height: 100%;
    max-width: 100%;
    opacity: 0.8;
}

.grid figure figcaption {
    padding: 2em;
    color: $white-color;
    text-transform: uppercase;
    font-size: 0.9em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    text-shadow: 0px 2px 3px $secondary-color;
}

.grid figure figcaption::before, .grid figure figcaption::after {
    pointer-events: none;
}

.grid figure figcaption, .grid figure figcaption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.grid figure figcaption > a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}

.grid figure h2 {
    font-weight: $light-weight;
    font-size: 1.75em;
    text-transform: capitalize;
}

.grid figure h2 span {
    font-size: $large-font;
    position: relative;
    top: 15px;
}

.grid figure h2,.grid figure p {
    margin: 0;
}

.grid figure p {
    letter-spacing: 1px;
    font-size: 85%;
}

figure.effect-bubba {
    background: $secondary-color;
}

figure.effect-bubba img {
    opacity: 0.7;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
}

figure.effect-bubba:hover img {
    opacity: 0.4;
}

figure.effect-bubba figcaption::before, figure.effect-bubba figcaption::after {
    position: absolute;
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}

figure.effect-bubba figcaption::before {
    border-top: 1px solid $white-color;
    border-bottom: 1px solid $white-color;
    -webkit-transform: scale(0,1);
    transform: scale(0,1);
}

figure.effect-bubba figcaption::after {
    border-right: 1px solid $white-color;
    border-left: 1px solid $white-color;
    -webkit-transform: scale(1,0);
    transform: scale(1,0);
}

figure.effect-bubba h2 {
    padding-top: 50px;
}

figure.effect-bubba p {
    padding: 20px 2.0em;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(0,20px,0);
    transform: translate3d(0,20px,0);
}

figure.effect-bubba:hover figcaption::before, figure.effect-bubba:hover figcaption::after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

figure.effect-bubba:hover h2, figure.effect-bubba:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}



@media screen and (max-width: 50em) {
    .content {
        padding: 0 10px;
        text-align: center;
    }
    .grid figure {
        display: inline-block;
        float: none;
        margin: 10px auto;
        width: 100%;
        max-height: 275px;
    }
}

.index-tag {
	padding: 50px 0px; 
	font-size: $largest-font; 
	font-weight: $light-weight; 
	width: 75%; 
	margin: 0 auto; 
	margin-top: 30% !important;
}

.mob-friendly{
	display:block;
}

.pac-input {
	background-color: $white-color;
	font-family: $primary-font;
	text-overflow: ellipsis;
	margin-top:10px;
}

.danger {
	color: #d9534f
}

.pac-container {
	font-family: $primary-font;
}

.pac-container {
	font-family: $primary-font;
}

.mark-creator-form {
	border: 0px;
    box-shadow: none;
    font-size: $normal-font;
    border-bottom: 1px dotted $gray-accent-color;
    padding: 20px 10px;
    color:#000;
    width:100%;
}

.trans-title {
	background:transparent;
	font-size:$normal-font;
}

#my-accord {
	background:transparent !important;
}

#my-accord .accordingly {
	background:$white-color;
	margin-bottom:10px;
}

#my-accord .title {
	font-size: $large-font;
    font-weight: $light-weight;
    padding: 20px;
}

input.mark-creator-form:focus {
	outline:0;
	border-bottom:2px solid #000;
	border-color: $primary-color;
}

#type-selector {
	color: $white-color;
	background-color: $primary-color;
	padding: 5px 11px 0px 11px;
}

#type-selector label {
	font-family: $primary-font;
	font-size: 13px;
	font-weight: $light-weight;
}
#target {
	width: 345px;
}

.active-ham {
    position:fixed;
    left:0%;
}

.ui.segment {
	background: transparent;
	border:0px;
	border-radius:0px;
	box-shadow: none;
}

.ui.card img, .card img {
    border: none;
}

.ui.card img, .card img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: inherit;
}

.panel-title {
    padding: 15px 5px;
}

.account-CTA {
	margin-top:10px;
}

.panel-bottom.text-center button {
	width: 100%;
	font-weight:300;
}

.only-success {
	color: #3c763d;
	background-color: #dff0d8;
	border-color: #d6e9c6;
	padding: 15px;
	margin-bottom: 20px;
	border: 1px solid transparent;
	border-radius: 4px;
}

.alert {
	padding: 17.5px 15px;
	margin-bottom: 20px;
	border: 1px solid transparent;
	border-radius: 4px;
	position: fixed;
	left: 0px;
	bottom: 0px;
	width: 100%;
	text-align: center;
	border-radius: 0px;
	border: 0px;
	margin: 0px;
	z-index: 10002;
	font-size: $normal-font;
	color:$white-color;
}

.alert button {
	margin-right:25px;
	font-size:24px;
}

.alert button span:hover, .alert button span:focus {
	color:$white-color;
}

.edit-button, .edit-button:hover, .edit-button:focus, .edit-button:visited {
	position: absolute;
    top: 7.5px;
    right: 15px;
    padding: 5px;
    font-size: $larger-font;
    color: $white-color;
}

.edit-button:hover {
	opacity:.85;
}

.success {
    background:rgba(69, 172, 239, .9);
    color: $white-color;
    text-align: center;
    font-weight: $normal-weight;
}

.alert-danger {
	background-color: #d9534f;
	border-color: #d6e9c6;
	color: $white-color;
	text-align: center;
	font-weight: $bold-weight;
	margin-top:5px;
	margin-bottom:0px;
}

.alert-info {
	background-color: #d9edf7;
	border-color: #d43f3a;
	color: #31708f;
	text-align: center;
	font-weight: $light-weight;
}

.dash-btn {
	padding: 15px;
	margin: 15px 0px 15px 15px;
	font-size: $normal-font;
}

.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
	border-top:0px;
}

.navbar-default .navbar-nav>li>a {
	color: $secondary-color;
}

.form-horizontal .form-group {
	margin-left: 0px;
	margin-right: 0px;
}

.searchify {
	font-size:18px; 
	padding:20px; 
	font-weight:300; 
	margin-top:20px;
}

.sign-in .margins25 a {
	font-size:36px;
	text-transform: uppercase;
}

.sign-in .margins25 a:hover, a:focus {
	text-decoration: none;
}

.row.row-broken {
    padding-bottom: 0;
}
.col-inside-lg {
    padding: 20px;
}

.item blockquote {
    border-left: none; 
    margin: 0;
}

.item blockquote img {
    margin-bottom: 10px;
}

.item blockquote p:before {
    float: left;
    margin-right: 10px;
}

.notifications {
	position: fixed;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: auto;
    width: 300px;
    margin-top: 55px;
    padding: 0px;
    float: none;
    z-index: 100030;
    border-right: 0px;
    overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
}

.atss {
	z-index:100 !important;
}

.notifications span {
	font-size:12px;
	letter-spacing: normal;
	text-transform: none;
	display:block;
	clear:both;
	padding:5px 15px;
}

.settings-drop {
    margin-left: -56px;
    margin-top: 19px;
    min-width: 225px;
}

.ui.popup {
    font-size:1.0rem;
    z-index:5200;
}

#results-loader-text {
    font-size:24px;
}

.landing-page-head {
    padding:150px 100px;
    background:url('../../img/vacation-rental.jpeg');
    background-size:cover;
    color:$white-color;
}

.vacation-banner {
    padding:100px 0px;
    background: url('../../img/vacation-logos.png');
}

.vacation-banner h3 {
    width: 60%; 
    margin: 0 auto;
}

.landing-page-head .new-lang-search .ui.search .prompt {
    width:600px;
}

.empty {
    display:none;
}

.ui.fluid.search .results {
    border:none;
}


.ui.tabular.menu + .bottom.attached.segment {
    background: $white-color;
}

.ui.attached.tabular.menu {
    margin-top:-18px;
    border-bottom: 12.5px solid $white-color;
}

.ui.tabular.menu .item {
    border-top: 3px solid #f4f4f4;
    background: #f4f4f4;
    border-radius: 0px;
    font-size: 14px;
    padding: 15px 5px;
    width: 20%;
    border-right: 1px solid $gray-accent-color;
}

.ui.tabular.menu .active.item {
    border-top: 3px solid $primary-color;
    border-right: none;
    border-left: none;
    border-radius:0px !important;
    font-weight:normal;
    margin-bottom:0px;
    background: $white-color;
}

.ui.top.attached.menu > .item:first-child {
    border-radius: 0px;
}

.ui.tabular.menu .item:hover  {
    border-top: 3px solid $primary-color;
    background: #f4f4f4;
    opacity: 1.0;
}

.ui.tabular.menu .item:active, .ui.tabular.menu .active.item:active, .ui.tabular.menu .active.item:hover {
    border-top: 3px solid $primary-color;
    background: $white-color;
    opacity: 1.0;    
}

.ui.attached + .ui.attached.segment:not(.top) {
    background:$white-color;
    padding:0px;
}

.ui.menu .item > .label {
    font-size:10px;
}

.compare-icon {
    width:25px !important;
    height:25px !important;
    margin-right:5px !important;
}

.unlocked {
    padding: 150px;
    font-size: 40px;
    color:$white-color;
}

.unlocked .btn-primary {
    background: $red-color;
    width:40%;
}

.compare-title {
    padding: 0px 10px;
    position: relative;
    top: 12.5px;
    letter-spacing: normal;
    text-transform: capitalize;
    font-size: $normal-font;
}

.ui.menu:not(.vertical) .item {
    display:block;
}

.compare p {
    font-size: 12px;
    position: relative;
    left: -6px;
    color:$gray-color;
    margin-top: -15px !important;
    margin-bottom: 7.5px !important;
}

.product-wrapper {
    padding:100px 25px;
}

.product-wrapper h2 {
    color: $primary-color;
    font-weight: $normal-weight;
    font-size: 25px;
    text-align: center;
    line-height: 1.3;
    letter-spacing: -.015em;
}

.product-wrapper p {
    font-size: $large-font;
    line-height: 1.6;
    letter-spacing: -.01em;
    color: gray;
    font-weight: 300;
}

.product-details-wrapper {
    padding:150px 0px;
}

.product-details-wrapper h3 {
    font-size: $larger-font;
    line-height: 1.3;
    letter-spacing: -.015em;
}


.product-details-wrapper .inner-content {
    width:80%;
    margin:0 auto;
    padding: 80px 0px;
}

.product-details-wrapper .inner-content p {
    font-size: $normal-font;
    line-height: 1.6;
    letter-spacing: -.01em;
}

.cta-banner {
    padding: 100px 0px 250px 0px;
    background:$off-white-color;
}

.cta-banner h2 {
    font-size:$largest-font;
    line-height: 1.3;
    letter-spacing: .1em;
    text-transform: uppercase;
    font-weight: $bold-weight;
}

.cta-banner h3 {
    font-size:$larger-font;
    line-height: 1.3;
    font-weight: $bold-weight;
}

.cta-banner p {
    font-size: $large-font;
    line-height: 1.6;
    color: $gray-color;
    font-weight: $normal-weight;
    padding: 10px 0px 60px 0px
}

.home-gradient {
    background: $off-white-color;
}

#slideUpEffect {
    visibility: hidden;
}

.pricing-feature-set {
    padding:0px;
}

.pricing-feature-set .set {
    width: 80%;
    margin: 0px auto;
    padding: 15px 0px;
}

.pricing-feature-set h3 {
    font-size:20px;
}

.pricing-feature-set h4 {
    font-size: 20px;
}

.pricing-box .btn-alt {
    position: relative;
    top: 20px;
}

.customer-set h3 {
    font-size:$larger-font;
}

.dash-navigation {
    position: fixed;
    padding-top: 75px;
    height: 100%;
    border-right: 1px solid $gray-accent-color;
    box-shadow: 0px 2px 3px $gray-accent-color;
    background: $off-white-color;
}

.dash-navigation ul {
    list-style-type:none;
}

.dash-navigation ul li {
    font-size: $large-font;
    padding:12.5px 0px;
}

.dash-navigation .fa {
    width: 40px;
    margin-left: -40px;
    text-align: center;
}

.dash-navigation ul li.active {
    color:$primary-color;
}

.dash-navigation .title {
    border: 0px;
    font-size: 12px;
    color: gray;
    padding: 0px;
    margin-bottom: 5px;
    margin-left: -30px;
}

.green {
    color: $green-color;
    font-size: $normal-font;
}

.no-border {
    border: none;
    display: block;
}

.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
    box-shadow:none;
}

label.radio-inline {
    font-size:$normal-font;
}

#payment-form .title {
    color: $secondary-color;
    font-size: $larger-font;
    font-weight: $bold-weight;
}

.badge {
    text-transform: lowercase !important;
}

.see-more {
    color:$primary-color;
    font-size: $normal-font !important;
}

.floating-box {
    background:$white-color; 
    width:70%; 
    margin:0 auto; 
    margin-top:-125px; 
    margin-bottom:100px; 
    box-shadow:0px 2px 3px $gray-accent-color;
}

.inverted-product-details {
    background:$off-white-color; 
    padding: 100px 50px 250px 50px;
}

.inverted-product-details p {
    line-height:1.6;
    font-size: $small-font;
}

.set h3 {
    font-size: $large-font;
    padding: 15px 0px;
    margin: 0px;
}

.set p {
    font-size: $normal-font;
    font-weight:$normal-weight;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
    border-color: $primary-color;
    background-color: $primary-color;
}

.mapbox-form-label {
    background: #0090ff !important;
}

.mapbox-directions-profile label, .mapbox-directions-profile {
    border-radius:0px !important;
}

.mapbox-directions-profile label {
    margin-bottom: 0px !important;
}

.mapboxgl-ctrl-attrib .mapbox-improve-map {
    display: none !important;
}

table.dataTable.no-footer {
    border-bottom: 1px solid $off-white-color !important;
    margin-bottom: 15px;
}

.copy {
    color:gray;
    font-size: $small-font;
}

.table-pricing tr {
    border-top:1px solid $off-white-color;
}

.table-pricing td {
    border-left:1px solid $off-white-color;
}

.ui.four > .value, .ui.statistics .statistic > .value {
    font-size: 2rem;
    line-height: 5rem;
}

.ui.statistic > .label, .ui.statistics .statistic > .label {
    font-size:$normal-font;
}

.sales-box {
    box-shadow:0px 2px 3px $gray-accent-color; 
    color:$white-color; 
    width:80%; 
    margin:0 auto;
}

.sales-box .inner-sales-box {
    padding:60px 30px;
}

.sales-box .title {
    font-size: 30px; 
    color: $white-color;
    padding-bottom: 0px;
}

.sales-box .title i.icon {
    position: relative;
    top: 5px;
}

.banner .sentence {
    padding: 50px 75px;
}

.table-pricing .btn-alt, .table-pricing .active-plan {
    position: relative; 
    top: 20px;
}

.map-creation .title {
    border: 0px; 
    font-size: $normal-font; 
    color: $secondary-color; 
    display:block;
    margin:0 auto;
}

.map-creation .selected-map-type {
    padding: 10px 20px;
    border: 1px solid $white-color;
    border-radius: 5px;
    font-size: $large-font;
}

.map-creation .selected-map-type:hover, .selected-map-style {
    cursor: pointer;
}

.map-creation .selected-map-type.active {
    color: $primary-color;
    padding: 10px 20px;
    border: 1px solid $primary-color;
    border-radius: 5px;
    position: relative;
}

.map-creation .selected-map-type.active:after {
    content: "\f00c";
    font-family: FontAwesome;
    right: 20px;
    position: absolute;
    top: 10px;
    font-size: $large-font;
 }

.selected-map-style.activeStyle {
    color: $primary-color;
    padding: 10px 20px;
    border: 0px;
    border-radius: 0px;
    position: relative;
 }

.selected-map-style.activeStyle:after {
     content: "\f00c";
    font-family: FontAwesome;
    right: 5%;
    position: absolute;
    top: 5%;
    font-size: $larger-font;
 }

.built-on-base {
    position: fixed; 
    bottom: 10px; 
    left: 26%; 
    z-index: 5002; 
    height: 24px;
}

#upgradeModalLabel {
    color: $secondary-color;
    font-size:$large-font; 
    font-weight: $normal-weight;
}

#upgradeModal .modal-body p {
    font-size:$larger-font; 
    padding:50px; 
    text-align: center;
}

#upgradeModal .modal-content {
    margin-top:150px;
}

#features section:hover {
    background:$off-white-color;
    cursor:pointer;
}

.ui.secondary.menu .active.item {
    box-shadow: none;
    color: $white-color;
    border-radius: 0px;
    border-right: 0px;
    background: transparent;
}

.ui.secondary.menu .item {
    font-size:$normal-font;
    color: $light-gray-color;
}

.ui.secondary.menu a.item:hover, .ui.secondary.menu .active.item:hover {
    background: transparent;
    color: $white-color;
    border-radius:0px;
    border-right: 0px;
}

.ui.secondary.menu {
    background: 0 0;
    margin-left: -.35714286em;
    margin-right: -.35714286em;
    border-radius: 0;
    border: none;
    box-shadow: none;
    border-right: 0px;
}

.dashboard-locations {
    padding:0px 0px 50px 0px;
}

.dashboard-locations .cover {
    background: $secondary-color;
    padding: 13px 0px;
}

.dashboard-locations .prof-user-img {
    height:75px; 
    width:75px;
    box-shadow: none;
}

.dashboard-locations .map-details {
    margin-top: 12px;
}

.dashboard-locations .map-details .title {
    color: $white-color; 
    border: 0px; 
    padding: 0px;
    font-size: $large-font;
    margin-bottom:0px;
}

.dashboard-locations .map-details p {
    color: $white-color;
    padding: 5px 0px;
    font-size:14px;
}

.dashboard-locations .map-actions {
    text-align: right;
}

#sharing-install .title, #location-creation .title, .map-settings .title {
    border: 0;
    font-size: $small-font;
    margin-bottom: 5px;
}

#location-creation label span {
    padding: 0px;
}

.get-started-wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(24,24,24,.8);
    z-index: 50000;
    display: none;
}

.get-started {
    background: $white-color;
    padding: 25px;
    position: relative;
    top: 75px;
    width: 80%;
    margin: 0 auto;
    height: 475px;
}

.mapboxgl-popup {
    top: -20px !important;
}

.mapboxgl-popup-content {
    width:200px;
}

.popup-title {
    font-size:$large-font;
    padding: 0px;
    margin: 10px 0px;
    font-weight: bold;
}

.mapboxgl-popup-close-button {
    font-size: $larger-font;
}

.activeCategory, .activeCategory:focus {
    color: #0090ff;
    outline: none;
}

.selectMarker {
    border:1px solid #fff;
}

.activeMarker {
    border:1px solid #0090ff;
}

#currentSelectedMarker {
    padding: 0px 25px;
}

.marker {
    border-radius:0px !important;
    background-size:cover !important;
}

.pricing-info {
    color: $primary-color; 
    position: relative; 
    font-size:$normal-font;
    text-decoration: underline; 
    left: 10px;
    top:-2px;
    display: inline-block;
}

.marker-category-selection {
    position:fixed; 
    overflow-y:scroll; 
    height: 382px; 
    border-right: 1px solid $off-white-color;
}

.map-demos {
    width: 100%;
    margin: 0 auto;    
}

.map-demos:hover {
    box-shadow: 0 18px 12px -11px rgba(0,0,0,.1);
    -webkit-transform: scale(1.0125);
    -ms-transform: scale(1.0125);
    transform: scale(1.0125);
}

.map-demos img {
    border:1px solid $gray-accent-color;
    border-radius:0px 0px 3px 3px;
    width:100%;
    
}

.toolbar {
    height: 20px;
    border-bottom: 1px solid #e9e9e9;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background: $white-color;
    border-top: 1px solid $gray-accent-color;
    border-left: 1px solid $gray-accent-color;
    border-right: 1px solid $gray-accent-color;
}

.toolbar::before {
    content: "•••";
    display: block;
    padding-right: 5px;
    line-height: $normal-font;
    font-size: 10px;
    letter-spacing: 0.2em;
    color: $gray-accent-color;
    float: right;
}

.hide-directions, button.hide-directions:hover {
    z-index: 0;
    position: relative;
    top: 5px;
    padding: 5px 30px;
    background: $off-white-color;
    border: 1px solid $gray-accent-color !important;
    border-radius: 5px;
    color: $secondary-color;
    font-size: $small-font;
    font-weight: bold;
    opacity: 1.0;

}

div#features img {
    width: 100%;
}

.uploaded-photo {
    max-width:150px; 
    padding: 15px 0px 10px 0px;
}

.uploaded-photo img {
    width:100%;
}

.mapboxgl-popup-content img {
    width: 100%;
}

.mapboxgl-popup-content {
    padding:0px; 
}

.mapboxgl-popup-content div {
    padding:10px;
}

.mapboxgl-popup-content p, p.location-address {
    margin: 0px;
}

section button.map-btn {
    margin-top:15px;
}

.mapboxgl-popup-content button.map-btn {
    margin-top:5px;
}

.team-mobile {
    width:50%;
}


i.icon.dropdown:before, i.icon.triangle.down:before {
    content: "\f107" !important;
}

.industries-menu .ui.list .list > a.item, .ui.list > a.item {
    font-size: $normal-font;
    color: gray !important;
    font-weight: 400;
}

.map-editor-navigation {
    list-style-type:none; 
    display:block !important; 
    padding: 25px 5px; 
    background: $secondary-color !important;
    border-right: 1px solid #ccc !important;
    margin:0 auto !important;
    position: fixed; 
    bottom: 0px; 
    top: 57px;
    width:150px;
    text-align: center;
    overflow-y: auto;
    z-index: 1000;
    height:100%;
}

.map-editor-navigation li {
    padding: 0px;
    text-align: left;
    position: relative;
    left: 10px;
}

.ui.secondary.menu .item {
    outline: 0;
    padding: 15px 0px;
}

.dashboard-content .ui.secondary.menu .item {
    padding: .71428571em .92857143em !important;
}

.shops {
    padding: 25px 0px;
}

.shops img {
    width:80%;
}

.btn-alt i.icon, .btn-primary i.icon {
    font-size: 20px;
    position: relative;
    top: 4px;
    margin-top: -4px;
}
.map-settings-info {
    padding-top: 15px;
    color: gray;
    font-size: 12px;
}


.empty-state h5 {
    font-size: 24px; 
    font-weight: 300;
    padding: 25px 0px 0px 0px;
}

.empty-state div {
    color: $primary-color;
    font-size: 70px;
}

.empty-state p {
    color: gray; 
    padding: 0px 0px 25px 0px;
}

.bar {
    transition: height .3s;
    background: $primary-color;
    position: fixed;
    top: 56px;
    height: 3px;
}

#oval-activate {
    height: 24px;
    display:inline;
    position:relative;
    top:-2px;
    padding: 0px 10px;
}

.disabled-link {
   pointer-events: none;
   cursor: default;
   opacity:.8;
}

.fixed-type-menu {
    background: $secondary-color !important;
    position: fixed;
    top: 56px;
    padding: 15px;
    left: 0px;
    right: 0px;
    z-index: 1000;
}

.maps-tab {
    margin-top: 50px !important;
}

#context2 {
    margin-top: 75px;
}

.base-blue {
    background: $primary-color;
}

.dash-back {
    padding: 5px 0px !important;
}

.base-pre-wrapper {
    background: $white-color;
    position: fixed;
    left: 155px;
    right: 0px;
    bottom: 0px;
    top: 57px;
    z-index: 1000;
}

.base-pre-loader {
    position: fixed;
    z-index: 9999;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    height: 50px;
    width: 50px;
}

.base-map-pre-wrapper {
    background: $white-color;
    position: fixed;
    left: 25%;
    right: 0px;
    bottom: 0px;
    top: 0px;
    z-index: 99999;
    opacity:.8;
}

.base-map-pre-loader {
    z-index: 9999;
    font-size: $normal-font;
    position: absolute;
    top: 50%;
    left: 55%;
    margin-top: -50px;
    margin-left: -100px;
}

.css-loader {
    border: 4px solid $gray-accent-color;
    border-radius: 50%;
    border-top: 4px solid $white-color;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
    margin-top: -3px;
    margin-bottom: -3px;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.inner-wrapper .ui.tab.segment.col-md-11 {
    width: 85%;
    left: 155px;
    top:42px;
    padding-bottom:100px;
}

#payment-form .radio-inline {
    font-size: $large-font;
    padding: 25px 35px;
}

.payment-errors {
    width: 100%;
    text-align: center;
    background: $white-color;
    color: $red-color;
    padding: 5px 0px;
    margin-bottom: 10px;
    display:none;
    font-weight: $bold-weight;
}

.from-the-blog {
    text-align: center; 
    padding: 0px 0px 150px 0px;
}

.from-the-blog h3 {
    font-size: $larger-font; 
    padding: 50px 0px; 
    color: $primary-color; 
    font-weight: $light-weight; 
    line-height: 1.3; 
    letter-spacing: -.015em;
}

.from-the-blog .the-post {
    width:100%; 
    height:250px;
}

.from-the-blog .the-post:hover {
    box-shadow: 0 18px 12px -11px rgba(0,0,0,.1);
    -webkit-transform: scale(1.0125);
    -ms-transform: scale(1.0125);
    transform: scale(1.0125);
}

.from-the-blog .blog-title {
    font-size: $large-font;
    width: 80%;
    margin: 0 auto;
    position: relative;
    top: 100px;
    color: $white-color;
    font-weight: $light-weight;
}

.plan-option {
    width: 50%;
    float: left;
    border-right: 10px solid $white-color;
    border-bottom: 10px solid $white-color;
}

.plan-option:hover {
    cursor: pointer;
}

.plan-option ul {
    list-style-type: none;
    padding-left: 0px;
    margin-top: 10px;
}

#payment-form .plan-option li {
    font-size:$normal-font;
    font-weight: $normal-weight;
    padding: 0px;
}

#payment-form .plan-option label {
   background: $off-white-color;
   width:100%;
}

#payment-form .plan-option label.radio-inline.active {
    color: $white-color;
    background: $secondary-color;
}

.carousel, .carousel-inner {
    position: relative;
    top: 12px !important;
}

#carousel-welcome h2 {
    color: $secondary-color;
    font-weight: $bold-weight;
    font-size: $larger-font;
    line-height: 1.3;
    letter-spacing: -.015em;
}

#carousel-welcome p {
    font-size: $large-font;
    line-height: 1.6;
    letter-spacing: -.01em;
    color: gray;
    font-weight: $light-weight;
}

#carousel-welcome .carousel-indicators li {
    display: inline-block;
    width: 20px;
    height: 5px;
    margin: 1px;
    text-indent: -999px;
    border: 1px solid $gray-accent-color;
    border-radius: 0px;
    cursor: pointer;
    background-color: #000 \9;
    background-color: transparent;
}

#carousel-welcome .carousel-indicators .active {
    width: 20px;
    height: 5px;
    background-color: $off-white-color;
}

#carousel-welcome .modal-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0px;
    right: 0px;
}

.carousel-inner {
    height: 420px;
}

.carousel-indicators {
    bottom:36px !important;
}

.home-start {
    padding:15px 30px !important; 
    color:$white-color !important; 
    background: $primary-color !important; 
}

.info-box {
    background: $white-color; 
    padding: 75px 50px; 
    text-align: left; 
    width:50%;
}

.non-segment {
    width: 85%;
    left: 155px;
    top: 50px;
    padding-bottom: 100px;
}

.coordinates {
    background: rgba(0,0,0,0.5);
    color: #fff;
    position: absolute;
    bottom: 10px;
    left: 10px;
    padding:5px 10px;
    margin: 0;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    display: none;
}

.button-bar {
    position: fixed;
    bottom: 20px;
    left: 165px;
    padding: 10px;
    width: 40%;
}

.button-bar .btn-primary {
    margin: 0 auto;
    width: 70%;
    box-shadow: 0 14px 28px rgba(0,0,0,0.1), 0 10px 10px rgba(0,0,0,0.12);
}

.title-lg {
    font-size: $larger-font !important;
    padding-left: 10px !important;
}

.brand-logo {
    margin-left: 25.5px;
}

#mapbox-map {
    width: 45%;
    position: fixed;
    top: 56px;
    right: 0px;
    bottom: 0px;
}

#viewMap {
    margin-left: 50px;
    padding: 10px 15px;
    font-size: 12px;
    position: relative;
    top: 12.5px;
}

#createMapButton {
    max-width: 75%;
    box-shadow: 0 14px 28px rgba(0,0,0,0.1), 0 10px 10px rgba(0,0,0,0.12);
}

#full-frame {
    position:absolute; 
    left: 0; 
    right: 0; 
    bottom: 0; 
    top: 0px; 
}

.coe-image {
    height: 25px !important;
    width: 25px !important;
    float: right;
}

.total-results {
    padding: 10px 0px;
    margin: 0px;
    font-size: 16px;
    background: $off-white-color;
}

.radius-filter {
    float: right;
    position: absolute;
    top: 10px;
    left: 368px;
    background: $white-color;
    padding: 10px;
    border-radius: 0px 5px 5px 0px;
    z-index: 1000;
}


/*
 |--------------------------------------------------------------------------
 | Base Capital Homepage
 |--------------------------------------------------------------------------
 */

.intro-link {
    color: $white-color; 
    font-size:36px;
}

.intro-link .fa-angle-down {
    color: $white-color; 
}

#about {
    padding:100px 0px; 
    background: $white-color;
}

.about-wrap, #team .inner-team, .company-wrap, .contact-wrap {
    width:80%; 
    margin:0 auto;
}

.about-wrap h2, .inner-team h2, .company-wrap h2, .contact-wrap h2 {
    padding:0px 0px 25px 0px;
}

#team {
    padding:100px 0px;
}

.team-member {
    float: left; 
    width: 200px;
}

#team .inner-team h1 {
    color: $white-color; 
    font-size: 80px; 
    font-weight: bold; 
    float: left; 
    position: relative; 
    top: 0px; 
    left:25px; 
    width:40px;
}

#team .owner-wrap {
    position: relative; 
    right: 0px; 
    top: 25px;
}

#team .owner-desc {
    font-size: 16px; 
    width: 75%; 
    margin: 0 auto; 
    position: relative; 
    top: -100px; 
    margin-bottom: -100px; 
    left: 115px;
}

#team .team-wrap {
    position: relative; 
    top: 15px;
}

#team .team-wrap h4 {
    font-size:24px;
}

#companies {
    padding:100px 0px; 
    background: $white-color;
}

.company-wrap .set a {
    color: $primary-color;
}

#contact {
    padding:100px 0px;
}

.slash {
    font-size: 14px;
    position: relative;
    color: #ffffff;
    padding-right: 2.5px;
}

.xout img:hover {
    opacity: .75;
}

#ham {
    position: relative;
    top: -5px;
}