/*
 |--------------------------------------------------------------------------
 | Buttons
 |--------------------------------------------------------------------------
 */

.map-name a.btn-primary {
    margin-bottom: 10px;
    width: 100%;
    padding: 10px 0px;
}

.simple-cta {
    color: $primary-color !important;
}

#home-search .btn {
    width: 15%;
    height: 60px;
    float: right;
    font-size: 20px;
    font-weight: $light-weight;
    text-transform: capitalize;
}

.btn.clearButton {
    border: 1px solid $primary-color;
    background: transparent;
    font-size: $normal-font;
    position: relative;
    font-weight: $normal-weight;
    padding: 5px 15px;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 10px;
    color: $primary-color;
    letter-spacing: 0;
}

.btn.okBtn {
    background:$primary-color;
    color:$white-color;
    border-radius: 4px;
    padding:5px 20px;
}

.btn.cancelBtn {
    background:#da1a29;
    color:$white-color;
    border-radius: 4px;
    padding:5px 20px;
}

.ghost-btn {
    background: transparent;
    border-radius: 0px;
    border: 2px solid $secondary-color;
    color:$secondary-color;
}

.ghost-btn:hover {
    background:transparent;
    border:2px solid $secondary-color;
    color:$secondary-color;
}

.ghost-lt-btn {
    border-radius: 0;
    font-weight: 300;
    position: relative;
    padding: 5px 10%;
    float: left;
    width: 100%;
    border:none;
    background: transparent;
    border-radius: 0px;
    font-size: $normal-font;
}

.ghost-lt-btn:hover {
    background:transparent;
    border:none;
    opacity:.75;
    color:$white-color;
}

.ghost-lt-btn > .child {
    position: relative;
    left: 50%;
}

.ghost-lt-btn span {
    background: transparent;
    border: 2px solid $white-color;
    color: $white-color;
    padding: 10px 30px;
    position: relative;
    top: -10px;
    border-radius: 0px;
}

.btn {
    border-radius:0px;
    font-family: $secondary-font;
}

.btn-link {
    color: $primary-color;
}

.primer {
    color: $primary-color;
}

.btn-delete-icon {
    background-color: transparent;
    border: none;
    position: relative; 
    top: -3px;
}

.btn-delete-icon:hover {
    background-color: transparent;
    border: none;
    color: $primary-color;
}

.btn-primary {
    border:0px;
    padding:10px 20px;
    width: 100%;
    border-radius: 0px;
    margin-bottom: 15px;
    max-width: 200px;
    text-align: center;
    background: $secondary-color;
    color: $white-color;
    font-size: $normal-font;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
    background: $secondary-color;
}

.btn-primary:disabled {
    background: $primary-color;
    opacity: .75;
}

.btn-default {
    width: 100%;
    box-shadow: 0px 2px 3px $light-gray-color;
}

.btn-primary:focus, .btn-primary:active {
    outline:none;
    outline-style:none;
}

.btn-primary:hover {
    cursor: pointer;
}

.services-box .btn-primary {
    position: absolute;
    bottom: 0px;
    max-width: 100%;
    width: 80%;
    left: 40px;
}

.services-box .btn-success {
    position: absolute;
    bottom: 0px;
    max-width: 100%;
    width: 80%;
    left: 40px;
}

.btn-success {
    border: 0px;
    padding: 15px 30px;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 15px;
    box-shadow: 0px 2px 3px #ccc;
    max-width: 225px;
    text-align: center;
    background: #5cb85c;
    color: #ffffff;
    font-size: 15px;
}

.fav-btn {
    position: absolute;
    top: 10px;
    background: transparent;
    color: $white-color;
    z-index: 5000;
    margin-bottom: 35px;
    border: none;
    right: 10px;
}

.fav-btn:hover {
    color:$primary-color;
    border-color: $primary-color;
}

.btn-lg,.btn-group-lg>.btn{
    font-size:$large-font;
}

.affil-btn {
     border: 1px solid $light-gray-color;
     padding: 5px 10px;
     border-radius: 5px;
     margin-top: 5px;
     font-size: 12px;
 }

.btn-avatar {
    background: rgba(42, 45, 51, 0.0);
    height: 170px;
    width: 170px;
    border-radius: 100px;
    box-shadow: 0px 2px 3px rgba(0,0,0,0.0);
    border: 3px solid rgba(0,0,0,0.0);
}

.btn-avatar:hover {
    background: rgba(42, 45, 51, 0.5);
}

.btn-avatar #avatar-text {
    position:relative;
    top: 60px;
    display:none;
    color:$white-color;
    letter-spacing: normal;
}

.btn-header-cta {
    padding: 6px 12px !important;
    color: $primary-color;
}

.btn-header-cta:hover {
    opacity:.9; 
    color: $primary-color;
}

.lead-gen-cta {
    text-transform: capitalize;
    border-bottom: 1px dashed $primary-color;
}

.btn-danger, .btn-danger:hover, .btn-danger:focus, .btn-danger:active {
    text-transform: none;
    padding:15px;
    width: 100%;
    color:#c9302c !important;
    margin-bottom: 15px;
    background: transparent !important;
    box-shadow: none !important;
    border: 0px !important;
}

.btn-white {
    background: $white-color;
    padding: 10px;
    width: 50%;
    color: $dark-color;
    box-shadow: 0px 2px 3px $light-gray-color;
    border-radius: 5px;
    font-size: $normal-font;
}

.call-to-action {
    width: 55%; 
    padding: 15px; 
    margin-top: 25px;
}

.homepage-CTA {
    width:30%; 
    margin-top:25px;
}

/*
 |--------------------------------------------------------------------------
 | Affiliate Buttons
 |--------------------------------------------------------------------------
 */

.opentable-btn {
    background:#da3743;
}

.opentable-btn:hover, .opentable-btn:focus, .opentable-btn:active {
    background: #da3743;
}

.seatgeek-btn {
    background: #1673e6;
    max-width: 100%;
    width:100%;
}

.seatgeek-btn:hover, .seatgeek-btn:focus, .seatgeek-btn:active {
    background: #1673e6;
}

.fb-btn {
    background: #3B5998;
    max-width: 100%;
    width:100%;
}

.fb-btn:hover, .fb-btn:focus, .fb-btn:active {
    background: #3B5998;
}

.foursquare-btn {
    background:#2d5be3;
    max-width: 100%;
    width:100%;
}

.foursquare-btn:hover, .foursquare-btn:focus, .foursquare-btn:active {
    background: #2d5be3;
}

.homeaway-btn {
    background:#2a6ebb;
    max-width: 100%;
    width:100%;
}

.homeaway-btn:hover, .homeaway-btn:focus, .homeaway-btn:active {
    background: #2a6ebb;
}

.travelocity-btn {
    background:#004e93;
    max-width: 100%;
    width:100%;
}

.travelocity-btn:hover, .travelocity-btn:focus, .travelocity-btn:active {
    background: #004e93;
}

.booking-btn {
    background: #003580;
}

.booking-btn:hover, .booking-btn:focus, .booking-btn:active {
    background: #003580;
}

.amazon-btn {
    background: #f0c14b;
    max-width: 100%;
    width:100%;
}

.amazon-btn:hover, .amazon-btn:focus, .amazon-btn:active {
    background: #f0c14b;
}

.ui.action.input:not([class*="left action"]) > .button:last-child, .ui.action.input:not([class*="left action"]) > .buttons:last-child > .button, .ui.action.input:not([class*="left action"]) > .dropdown:last-child {
    border-radius:0px;
}

.btn-strong, .btn-strong:hover, .btn-strong:active, .btn-strong:focus {
    padding: 10px 30px;
    background: $primary-color;
    border: 1px solid $primary-color;
    border-radius: 5px;
    color: $white-color;
    font-size:$large-font;
}

.btn-alt, .btn-alt:hover, .btn-alt:active, .btn-alt:focus {
    padding: 14px 30px;
    border: 1px solid $primary-color;
    border-radius: 0px;
    color: $primary-color;
    font-size: $normal-font;
    position: relative;
    top: 1px;
    outline: 0;
    cursor: pointer;
}

.btn-alt:hover, .btn-strong:hover {
    opacity:.9;
}

.btn-white, .btn-white:hover, .btn-white:active, .btn-white:focus {
    padding: 14px 30px;
    border: 2px solid $white-color;
    border-radius: 5px;
    color: $white-color;
    font-size: $normal-font;
    background: transparent;
    position: relative;
    top: 1px;
    box-shadow: none;
    outline: 0;
}

.btn-white:hover, .btn-strong:hover {
    opacity:.9;
}

.btn-upgrade, .btn-upgrade:hover, .btn-upgrade:active {
    color: $white-color !important;
    background: $primary-color !important;
    padding: 10px 25px !important;
    border-radius: 0px;
    font-size: 14px !important;
    box-shadow: none;
}


button.map-btn {
    background: $off-white-color;
    padding: 2px 10px;
    border: 1px solid $gray-accent-color;
    border-radius: 5px;
    color: $secondary-color;
    font-size: $small-font;
    font-weight: bold;
    margin-right:5px;
}

.dark-shadow {
    box-shadow: 0px 2px 3px $secondary-color;
}

.ext-btn {
    padding: 16px 50px;
}

.red-btn, .red-btn:hover, .red-btn:active, .red-btn:focus {
    background:#F88379;
}

#settingsSubmitButton, #locationSubmitButton, #stylesSubmitButton, #installSubmitButton {
    width:100%;
    max-width: 100%;
}

button:focus {
    outline:0 !important;
}